var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "vacancies-list-replace" }, [
    _c(
      "div",
      { staticClass: "section-container" },
      [
        _c("vacancy-search", {
          attrs: {
            locale: _vm.locale,
            csrf: _vm.csrf,
            texts: _vm.texts,
            "search-value": _vm.query,
          },
          on: {
            submitSearch: _vm.submitSearch,
            "update-search-value": _vm.handleUpdateSearchValue,
          },
        }),
        _vm._v(" "),
        _c("vacancy-filters", {
          attrs: {
            "directions-prop": _vm.directionsProp,
            "locations-prop": _vm.locationsProp,
            "companies-prop": _vm.companiesProp,
            "levels-prop": _vm.levelsProp,
            "languages-prop": _vm.languagesProp,
            texts: _vm.texts,
            locale: _vm.locale,
          },
          on: {
            "submit-filters": _vm.onSubmitFilters,
            "clear-search": _vm.handleClearSearch,
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vacancies-section" },
          [
            _c("div", { staticClass: "vacancies-counter-block" }, [
              _c("div", { staticClass: "vacancies-counter" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.translate("Vacancies", _vm.translates)) +
                    ":\n                    "
                ),
                _c("span", { staticClass: "vacancies-counter-number" }, [
                  _vm._v(_vm._s(_vm.vacancies.length)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("vacancy-list", {
              attrs: {
                locale: _vm.locale,
                vacancies: _vm.vacancies,
                texts: _vm.texts,
                "bonus-btn-prop": _vm.bonusBtnProp,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }