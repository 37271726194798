var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-observer", { ref: "observer" }, [
    _c(
      "form",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.sendSuccess,
            expression: "!sendSuccess",
          },
        ],
        staticClass: "form-6",
        attrs: {
          id: "wf-form--2",
          name: "wf-form--2",
          "data-name": "Країна, де я зараз",
          method: "get",
          "data-wf-page-id": "6478cab686097afaff09664e",
          "data-wf-element-id": "1303c0f1-07c9-eb9e-90cf-3ee7aa07ca1a",
          "aria-label": "Країна, де я зараз",
        },
      },
      [
        _c(
          "div",
          { staticClass: "div-block-115" },
          [
            _c(
              "label",
              { staticClass: "field-label", attrs: { for: "field-4" } },
              [
                _vm._v("\n                " + _vm._s(_vm.translate("Name"))),
                _c("span", { staticClass: "text-span-21" }, [_vm._v("*")]),
              ]
            ),
            _vm._v(" "),
            _c("validation-provider", {
              staticClass: "input-wrapper width-full",
              attrs: {
                name: "name",
                rules: {
                  required: true,
                  maxName: 50,
                  regexName: _vm.nameRegex,
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.name,
                            expression: "name",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "text-field-7 w-input",
                        class: { "has-error": errors[0] },
                        attrs: {
                          id: "field-4",
                          name: "name",
                          type: "text",
                          maxlength: "256",
                        },
                        domProps: { value: _vm.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.name = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._v(" "),
                      errors[0]
                        ? _c("span", { staticClass: "validation-error-text" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.translate(errors[0])) +
                                "\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      errors[0]
                        ? _c("img", {
                            staticClass: "error-button",
                            attrs: { src: "/img/warning-icon.svg" },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "div-block-115" },
          [
            _c(
              "label",
              { staticClass: "field-label", attrs: { for: "field-5" } },
              [
                _vm._v(
                  "\n                " + _vm._s(_vm.translate("Last name"))
                ),
                _c("span", { staticClass: "text-span-21" }, [_vm._v("*")]),
              ]
            ),
            _vm._v(" "),
            _c("validation-provider", {
              staticClass: "input-wrapper width-full",
              attrs: {
                name: "surname",
                rules: {
                  required: true,
                  maxLastname: 50,
                  regexLastname: _vm.nameRegex,
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.lastname,
                            expression: "lastname",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "text-field-7 w-input",
                        class: { "has-error": errors[0] },
                        attrs: {
                          id: "field-5",
                          type: "text",
                          maxlength: "256",
                          "data-name": "Прізвище",
                          placeholder: "",
                          name: "surname",
                        },
                        domProps: { value: _vm.lastname },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.lastname = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._v(" "),
                      errors[0]
                        ? _c("span", { staticClass: "validation-error-text" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.translate(errors[0])) +
                                "\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      errors[0]
                        ? _c("img", {
                            staticClass: "error-button",
                            attrs: { src: "/img/warning-icon.svg" },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "div-block-115" }, [
          _c(
            "label",
            { staticClass: "field-label", attrs: { for: "field-7" } },
            [
              _vm._v("\n                " + _vm._s(_vm.translate("Location"))),
              _c("span", { staticClass: "text-span-21" }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "input-wrapper width-full" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.country,
                    expression: "country",
                  },
                ],
                staticClass: "select-field w-select",
                attrs: {
                  id: "field-7",
                  name: "country",
                  "data-name": "Країна, де я зараз",
                },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.country = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.loadCities(_vm.country, true)
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "" } }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.translate("Location")) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.countries, function (chooseCountry) {
                  return _c(
                    "option",
                    {
                      key: chooseCountry.name,
                      domProps: { value: chooseCountry.name },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(chooseCountry.name) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _c("option", { attrs: { value: "other" } }, [
                  _vm._v(
                    "\n                        Other\n                    "
                  ),
                ]),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.country,
                    expression: "country",
                  },
                ],
                staticClass: "select-delete",
              },
              [
                _c("close-icon", {
                  attrs: { size: 18, "fill-color": "#ffffff" },
                  on: {
                    click: function ($event) {
                      return _vm.clearCountry()
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "div-block-115" }, [
          _c(
            "label",
            { staticClass: "field-label", attrs: { for: "field-9" } },
            [
              _vm._v(
                "\n                " + _vm._s(_vm.translate("City")) + " "
              ),
              _c("span", { staticClass: "text-span-21" }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "input-wrapper width-full" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.city,
                    expression: "city",
                  },
                ],
                staticClass: "select-field w-select",
                attrs: {
                  id: "field-9",
                  name: "field-9",
                  "data-name": "Field 9",
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.city = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c(
                  "option",
                  { attrs: { disabled: "", selected: "", value: "" } },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.translate("City")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.cities, function (countryCity) {
                  return _c(
                    "option",
                    {
                      key: countryCity.name,
                      domProps: { value: countryCity.name },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(countryCity.name) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _c("option", { attrs: { value: "other" } }, [
                  _vm._v(
                    "\n                        Other\n                    "
                  ),
                ]),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.city,
                    expression: "city",
                  },
                ],
                staticClass: "select-delete",
              },
              [
                _c("close-icon", {
                  attrs: { size: 18, "fill-color": "#ffffff" },
                  on: {
                    click: function ($event) {
                      _vm.city = ""
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("validation-provider", {
          staticClass: "div-block-115",
          attrs: {
            name: "email",
            rules: { required: true, maxEmail: 50, email: _vm.email },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ errors }) {
                return [
                  _c(
                    "label",
                    { staticClass: "field-label", attrs: { for: "Name-6" } },
                    [
                      _vm._v(
                        "\n                " + _vm._s(_vm.translate("Email"))
                      ),
                      _c("span", { staticClass: "text-span-21" }, [
                        _vm._v("*"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper width-full" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.email,
                          expression: "email",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "text-field-7 w-input",
                      class: { "has-error": errors[0] },
                      attrs: {
                        id: "Name-6",
                        type: "email",
                        maxlength: "256",
                        name: "email",
                        "data-name": "Name 5",
                        placeholder: "",
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value.trim()
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _vm._v(" "),
                    errors[0]
                      ? _c("span", { staticClass: "validation-error-text" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.translate(errors[0])) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    errors[0]
                      ? _c("img", {
                          staticClass: "error-button",
                          attrs: { src: "/img/warning-icon.svg" },
                        })
                      : _vm._e(),
                  ]),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c("validation-provider", {
          staticClass: "div-block-115",
          attrs: {
            name: "number",
            rules: {
              required: true,
              numericMonthlySalary: true,
              maxMonthlySalary: 10,
            },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ errors }) {
                return [
                  _c(
                    "label",
                    { staticClass: "field-label", attrs: { for: "name-5" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.translate("Salary expectations per month"))
                      ),
                      _c("span", { staticClass: "text-span-21" }, [
                        _vm._v("*"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper width-full" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.salary,
                          expression: "salary",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "text-field-7 w-input",
                      class: { "has-error": errors[0] },
                      attrs: {
                        id: "name-5",
                        type: "text",
                        maxlength: "256",
                        name: "number",
                        "data-name": "Name 5",
                        placeholder: "",
                      },
                      domProps: { value: _vm.salary },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.salary = $event.target.value.trim()
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _vm._v(" "),
                    errors[0]
                      ? _c("span", { staticClass: "validation-error-text" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.translate(errors[0])) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    errors[0]
                      ? _c("img", {
                          staticClass: "error-button",
                          attrs: { src: "/img/warning-icon.svg" },
                        })
                      : _vm._e(),
                  ]),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c("validation-provider", {
          staticClass: "div-block-115",
          attrs: {
            name: "phone",
            rules: { required: true, maskIncorrectPhone: true },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ errors }) {
                return [
                  _c(
                    "label",
                    { staticClass: "field-label", attrs: { for: "phone" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.translate("Phone number"))
                      ),
                      _c("span", { staticClass: "text-span-21" }, [
                        _vm._v("*"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrapper width-full" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.phone,
                          expression: "phone",
                        },
                      ],
                      staticClass: "text-field-7 w-input",
                      class: { "has-error": errors[0] },
                      attrs: {
                        id: "phone",
                        type: "tel",
                        "data-name": "phone",
                        maxlength: "22",
                        minlength: "7",
                        name: "phone",
                        placeholder: "",
                        autocomplete: "off",
                      },
                      domProps: { value: _vm.phone },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.phone = $event.target.value
                          },
                          _vm.changePhone,
                        ],
                        click: _vm.clickPhone,
                        paste: _vm.pastePhone,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "delete",
                              [8, 46],
                              $event.key,
                              ["Backspace", "Delete", "Del"]
                            )
                          )
                            return null
                          return _vm.deletePhoneKey.apply(null, arguments)
                        },
                      },
                    }),
                    _vm._v(" "),
                    errors[0]
                      ? _c("span", { staticClass: "validation-error-text" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.translate(errors[0])) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    errors[0]
                      ? _c("img", {
                          staticClass: "error-button",
                          attrs: { src: "/img/warning-icon.svg" },
                        })
                      : _vm._e(),
                  ]),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "div-block-115" },
          [
            _c(
              "label",
              { staticClass: "field-label", attrs: { for: "messanger" } },
              [
                _vm._v(
                  "\n                " + _vm._s(_vm.translate("Messenger"))
                ),
                _c("span", { staticClass: "text-span-21" }),
              ]
            ),
            _vm._v(" "),
            _c("validation-provider", {
              staticClass: "input-wrapper width-full",
              attrs: { name: "messanger", rules: { maxSocialNetworkAcc: 500 } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.messenger,
                            expression: "messenger",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "text-field-7 w-input",
                        class: { "has-error": errors[0] },
                        attrs: {
                          id: "messanger",
                          type: "text",
                          maxlength: "256",
                          name: "messanger",
                          "data-name": "Messanger",
                          placeholder: "",
                        },
                        domProps: { value: _vm.messenger },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.messenger = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._v(" "),
                      errors[0]
                        ? _c("span", { staticClass: "validation-error-text" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.translate(errors[0])) +
                                "\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      errors[0]
                        ? _c("img", {
                            staticClass: "error-button",
                            attrs: { src: "/img/warning-icon.svg" },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "div-block-115" },
          [
            _c("label", { staticClass: "field-label", attrs: { for: "ld" } }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.translate("LinkedIn")) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("validation-provider", {
              staticClass: "input-wrapper width-full",
              attrs: { name: "ld", rules: { maxLinkedInAcc: 500 } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.linkedin,
                            expression: "linkedin",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "text-field-7 w-input",
                        class: { "has-error": errors[0] },
                        attrs: {
                          id: "ld",
                          type: "text",
                          maxlength: "256",
                          name: "ld",
                          "data-name": "Linkedin",
                        },
                        domProps: { value: _vm.linkedin },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.linkedin = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._v(" "),
                      errors[0]
                        ? _c("span", { staticClass: "validation-error-text" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.translate(errors[0])) +
                                "\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      errors[0]
                        ? _c("img", {
                            staticClass: "error-button",
                            attrs: { src: "/img/warning-icon.svg" },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "div-block-115" },
          [
            _c(
              "label",
              { staticClass: "field-label", attrs: { for: "field" } },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.translate("Leave your comment")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c("validation-provider", {
              staticClass: "input-wrapper width-full",
              attrs: { name: "comment", rules: { maxComment: 1000 } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.comment,
                            expression: "comment",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "textarea w-input comment-textarea",
                        class: { "has-error": errors[0] },
                        attrs: {
                          id: "field",
                          name: "comment",
                          maxlength: "5000",
                          "data-name": "Field 3",
                        },
                        domProps: { value: _vm.comment },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.comment = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._v(" "),
                      errors[0]
                        ? _c("span", { staticClass: "validation-error-text" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.translate(errors[0])) +
                                "\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      errors[0]
                        ? _c("img", {
                            staticClass: "error-button",
                            attrs: { src: "/img/warning-icon.svg" },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "div-block-115" },
          [
            _c(
              "label",
              { staticClass: "field-label", attrs: { for: "name-5" } },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.translate("Link to your portfolio")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c("validation-provider", {
              staticClass: "input-wrapper width-full",
              attrs: { name: "protfolio", rules: { maxPortfolioLink: 500 } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.portfolioLink,
                            expression: "portfolioLink",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "text-field-7 w-input",
                        class: { "has-error": errors[0] },
                        attrs: {
                          id: "name-5",
                          type: "text",
                          maxlength: "256",
                          name: "name-5",
                          "data-name": "Name 5",
                          placeholder: "",
                        },
                        domProps: { value: _vm.portfolioLink },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.portfolioLink = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._v(" "),
                      errors[0]
                        ? _c("span", { staticClass: "validation-error-text" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.translate(errors[0])) +
                                "\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      errors[0]
                        ? _c("img", {
                            staticClass: "error-button",
                            attrs: { src: "/img/warning-icon.svg" },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "div-block-115" },
          [
            _c(
              "label",
              { staticClass: "field-label", attrs: { for: "name-5" } },
              [
                _vm._v("\n                " + _vm._s(_vm.translate("CV"))),
                _c("span", { staticClass: "text-span-21" }, [_vm._v("*")]),
              ]
            ),
            _vm._v(" "),
            _c("validation-provider", {
              staticClass: "position-relative-vue",
              attrs: {
                name: "fileName",
                rules: { required_if: _vm.portfolioLink === "" },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _c("div", { staticClass: "input-wrapper" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.fileName,
                              expression: "fileName",
                            },
                          ],
                          attrs: { name: "fileName", type: "text" },
                          domProps: { value: _vm.fileName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.fileName = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "input__file",
                          attrs: {
                            id: "input__file",
                            name: "myFile",
                            type: "file",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.uploadResume($event)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.fileOriginalName,
                                expression: "!fileOriginalName",
                              },
                            ],
                            staticClass: "input__file-button",
                            class: {
                              "has-error": errors[0] || _vm.fileErrorText,
                            },
                            staticStyle: { "font-weight": "normal" },
                            attrs: { for: "input__file" },
                          },
                          [
                            _c("div", { staticClass: "div-block-125" }, [
                              _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: "/webflow-main/images/File-upload.svg",
                                    loading: "lazy",
                                    alt: "",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "text-block-55" }, [
                                _vm._v(_vm._s(_vm.translate("Attach your CV"))),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.fileOriginalName
                          ? _c(
                              "div",
                              {
                                staticClass: "div-block-125 file-uploaded",
                                class: {
                                  "has-error": errors[0] || _vm.fileErrorText,
                                },
                              },
                              [
                                _c("div", { staticClass: "text-block-55" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.fileOriginalName.length > 25
                                          ? _vm.fileOriginalName.slice(0, 5) +
                                              "..." +
                                              _vm.fileOriginalName.slice(-20)
                                          : _vm.fileOriginalName
                                      ) +
                                      "\n                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("close-icon", {
                                  staticClass: "close-icon-upload",
                                  attrs: { size: 18 },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteFile(_vm.fileName)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.fileErrorText || errors[0]
                          ? _c(
                              "span",
                              { staticClass: "validation-error-text" },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.fileErrorText
                                        ? _vm.fileErrorText
                                        : _vm.translate(errors[0])
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _vm.fileErrorText || errors[0]
                        ? _c("img", {
                            staticClass: "error-button file-uploader-error",
                            attrs: { src: "/img/warning-icon.svg" },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "divider" }),
        _vm._v(" "),
        _c("validation-provider", {
          staticClass: "position-relative-vue input-wrapper",
          attrs: { name: "checkbox", rules: { isEqual: true } },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ errors }) {
                return [
                  _c(
                    "label",
                    {
                      staticClass:
                        "w-checkbox checkbox-field-2 checkbox-privacy",
                    },
                    [
                      _c("div", {
                        staticClass:
                          "w-checkbox-ageement w-checkbox-input--inputType-custom checkbox-2",
                        class: {
                          "w--redirected-checked": _vm.agreement,
                          "has-error": errors[0],
                        },
                        staticStyle: { cursor: "pointer" },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.agreement,
                            expression: "agreement",
                          },
                        ],
                        staticStyle: {
                          opacity: "0",
                          position: "absolute",
                          "z-index": "-1",
                        },
                        attrs: {
                          id: "checkbox-3",
                          type: "checkbox",
                          name: "checkbox-3",
                          "data-name": "Checkbox 3",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.agreement)
                            ? _vm._i(_vm.agreement, null) > -1
                            : _vm.agreement,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.agreement,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.agreement = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.agreement = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.agreement = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      errors[0]
                        ? _c("span", { staticClass: "validation-error-text" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.translate("This is a required field")
                                ) +
                                "\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "checkbox-label-2 w-form-label checkbox-content-box",
                          attrs: { for: "checkbox-3" },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "link-25",
                              attrs: { href: _vm.privacy, target: "_blank" },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.translate(
                                      "I agree to the processing of personal data"
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-span-21" }, [
                            _vm._v("*"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  errors[0]
                    ? _c("img", {
                        staticClass: "error-button checkbox-item",
                        attrs: { src: "/img/warning-icon.svg" },
                      })
                    : _vm._e(),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c("div", { staticClass: "div-block-121" }, [
          _c("input", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.blocked,
                expression: "!blocked",
              },
            ],
            staticClass: "submit-button-3 w-button",
            attrs: { type: "submit", "data-wait": "Waiting..." },
            domProps: { value: _vm.translate("Submit a request") },
            on: { click: _vm.sendForm },
          }),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }