var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "vacancies-filters" }, [
      _c(
        "div",
        {
          staticClass: "filter-dropdown w-dropdown",
          class: { "dropdown-selected": _vm.selectedDirections.length },
        },
        [
          _c(
            "div",
            {
              staticClass: "dropdown-toggle w-dropdown-toggle",
              on: {
                click: function ($event) {
                  return _vm.addFilterBg($event)
                },
              },
            },
            [
              _c("div", { staticClass: "dropdown-text" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.translate("Direction", _vm.translates)) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _vm.selectedDirections.length
                ? _c("div", { staticClass: "selection-counter" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.selectedDirections.length) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("img", {
                staticClass: "dropdown-arrow",
                attrs: {
                  src: "/img/dropdown-arrow.svg",
                  loading: "lazy",
                  alt: "",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "nav",
            { ref: "directions", staticClass: "dropdown-list w-dropdown-list" },
            [
              _c(
                "div",
                { staticClass: "dropdown-wrapper" },
                [
                  _vm._l(_vm.directions, function (direction) {
                    return _c(
                      "label",
                      {
                        key: direction.id,
                        staticClass: "w-checkbox check-field",
                      },
                      [
                        _c("div", {
                          staticClass:
                            "w-checkbox-input w-checkbox-input--inputType-custom filter-checkbox-check",
                          class: { "w--redirected-checked": direction.checked },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "checkbox-input",
                          attrs: {
                            id: "Checkbox",
                            type: "checkbox",
                            name: "Checkbox",
                            "data-name": "Checkbox",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.setFilter(direction, "directions")
                            },
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "filter-checkbox-label w-form-label",
                            attrs: { for: "Checkbox" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(direction.name) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "filter-done" }, [
                    _c(
                      "a",
                      {
                        staticClass: "filter-done-button w-inline-block",
                        attrs: { href: "#" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-block-62",
                            on: {
                              click: function ($event) {
                                return _vm.filterItems({
                                  filterName: "directions",
                                  filterSelectedName: "selectedDirections",
                                })
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.translate("Done", _vm.translates)) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "filter-dropdown w-dropdown",
          class: { "dropdown-selected": _vm.selectedLocations.length },
          attrs: { "data-hover": "false", "data-delay": "0" },
        },
        [
          _c(
            "div",
            {
              staticClass: "dropdown-toggle w-dropdown-toggle",
              on: {
                click: function ($event) {
                  return _vm.addFilterBg($event)
                },
              },
            },
            [
              _c("div", { staticClass: "dropdown-text" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.translate("Location", _vm.translates)) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _vm.selectedLocations.length
                ? _c("div", { staticClass: "selection-counter" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.selectedLocations.length) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("img", {
                staticClass: "dropdown-arrow",
                attrs: {
                  src: "/img/dropdown-arrow.svg",
                  loading: "lazy",
                  alt: "",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "nav",
            { ref: "locations", staticClass: "dropdown-list w-dropdown-list" },
            [
              _c(
                "div",
                { staticClass: "dropdown-wrapper" },
                [
                  _vm._l(_vm.locations, function (location) {
                    return _c(
                      "label",
                      {
                        key: location.id,
                        staticClass: "w-checkbox check-field",
                      },
                      [
                        _c("div", {
                          staticClass:
                            "w-checkbox-input w-checkbox-input--inputType-custom filter-checkbox-check",
                          class: { "w--redirected-checked": location.checked },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "checkbox-input",
                          attrs: {
                            id: "Checkbox",
                            type: "checkbox",
                            name: "Checkbox",
                            "data-name": "Checkbox",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setFilter(location, "locations")
                            },
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "filter-checkbox-label w-form-label",
                            attrs: { for: "Checkbox" },
                          },
                          [_vm._v(_vm._s(location.name))]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "filter-done" }, [
                    _c(
                      "a",
                      {
                        staticClass: "filter-done-button w-inline-block",
                        attrs: { href: "#" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-block-62",
                            on: {
                              click: function ($event) {
                                return _vm.filterItems({
                                  filterName: "locations",
                                  filterSelectedName: "selectedLocations",
                                })
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.translate("Done", _vm.translates)) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "filter-dropdown w-dropdown",
          class: { "dropdown-selected": _vm.selectedCompanies.length },
          attrs: { "data-hover": "false", "data-delay": "0" },
        },
        [
          _c(
            "div",
            {
              staticClass: "dropdown-toggle w-dropdown-toggle",
              on: {
                click: function ($event) {
                  return _vm.addFilterBg($event)
                },
              },
            },
            [
              _c("div", { staticClass: "dropdown-text" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.translate("Company", _vm.translates)) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _vm.selectedCompanies.length
                ? _c("div", { staticClass: "selection-counter" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.selectedCompanies.length) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("img", {
                staticClass: "dropdown-arrow",
                attrs: {
                  src: "/img/dropdown-arrow.svg",
                  loading: "lazy",
                  alt: "",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "nav",
            { ref: "companies", staticClass: "dropdown-list w-dropdown-list" },
            [
              _c(
                "div",
                { staticClass: "dropdown-wrapper" },
                [
                  _vm._l(_vm.companies, function (company) {
                    return _c(
                      "label",
                      {
                        key: company.id,
                        staticClass: "w-checkbox check-field",
                      },
                      [
                        _c("div", {
                          staticClass:
                            "w-checkbox-input w-checkbox-input--inputType-custom filter-checkbox-check",
                          class: { "w--redirected-checked": company.checked },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "checkbox-input",
                          attrs: {
                            id: "Checkbox",
                            type: "checkbox",
                            name: "Checkbox",
                            "data-name": "Checkbox",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setFilter(company, "companies")
                            },
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "filter-checkbox-label w-form-label",
                            attrs: { for: "Checkbox" },
                          },
                          [_vm._v(_vm._s(company.name))]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "filter-done" }, [
                    _c(
                      "a",
                      {
                        staticClass: "filter-done-button w-inline-block",
                        attrs: { href: "#" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-block-62",
                            on: {
                              click: function ($event) {
                                return _vm.filterItems({
                                  filterName: "companies",
                                  filterSelectedName: "selectedCompanies",
                                })
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.translate("Done", _vm.translates)) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "filter-dropdown w-dropdown",
          class: { "dropdown-selected": _vm.selectedLevels.length },
          attrs: { "data-hover": "false", "data-delay": "0" },
        },
        [
          _c(
            "div",
            {
              staticClass: "dropdown-toggle w-dropdown-toggle",
              on: {
                click: function ($event) {
                  return _vm.addFilterBg($event)
                },
              },
            },
            [
              _c("div", { staticClass: "dropdown-text" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.translate("Level", _vm.translates)) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _vm.selectedLevels.length
                ? _c("div", { staticClass: "selection-counter" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.selectedLevels.length) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("img", {
                staticClass: "dropdown-arrow",
                attrs: {
                  src: "/img/dropdown-arrow.svg",
                  loading: "lazy",
                  alt: "",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "nav",
            { ref: "levels", staticClass: "dropdown-list w-dropdown-list" },
            [
              _c(
                "div",
                { staticClass: "dropdown-wrapper" },
                [
                  _vm._l(_vm.levels, function (level) {
                    return _c(
                      "label",
                      { key: level.id, staticClass: "w-checkbox check-field" },
                      [
                        _c("div", {
                          staticClass:
                            "w-checkbox-input w-checkbox-input--inputType-custom filter-checkbox-check",
                          class: { "w--redirected-checked": level.checked },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "checkbox-input",
                          attrs: {
                            id: "Checkbox",
                            type: "checkbox",
                            name: "Checkbox",
                            "data-name": "Checkbox",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setFilter(level, "levels")
                            },
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "filter-checkbox-label w-form-label",
                            attrs: { for: "Checkbox" },
                          },
                          [_vm._v(_vm._s(level.name))]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "filter-done" }, [
                    _c(
                      "a",
                      {
                        staticClass: "filter-done-button w-inline-block",
                        attrs: { href: "#" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-block-62",
                            on: {
                              click: function ($event) {
                                return _vm.filterItems({
                                  filterName: "levels",
                                  filterSelectedName: "selectedLevels",
                                })
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.translate("Done", _vm.translates)) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.locale !== "bg" && _vm.locale !== "ru"
        ? _c(
            "div",
            {
              staticClass: "filter-dropdown w-dropdown",
              class: { "dropdown-selected": _vm.selectedLanguages.length },
            },
            [
              _c(
                "div",
                {
                  staticClass: "dropdown-toggle w-dropdown-toggle",
                  on: {
                    click: function ($event) {
                      return _vm.addFilterBg($event)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "dropdown-text" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.translate("Language", _vm.translates)) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.selectedLanguages.length
                    ? _c("div", { staticClass: "selection-counter" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.selectedLanguages.length) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "dropdown-arrow",
                    attrs: {
                      src: "/img/dropdown-arrow.svg",
                      loading: "lazy",
                      alt: "",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "nav",
                {
                  ref: "languages",
                  staticClass: "dropdown-list w-dropdown-list",
                },
                [
                  _c(
                    "div",
                    { staticClass: "dropdown-wrapper" },
                    [
                      _vm._l(_vm.languages, function (language) {
                        return _c(
                          "label",
                          {
                            key: language.id,
                            staticClass: "w-checkbox check-field",
                          },
                          [
                            _c("div", {
                              staticClass:
                                "w-checkbox-input w-checkbox-input--inputType-custom filter-checkbox-check",
                              class: {
                                "w--redirected-checked": language.checked,
                              },
                            }),
                            _vm._v(" "),
                            _c("input", {
                              staticClass: "checkbox-input",
                              attrs: {
                                id: language.slug,
                                type: "checkbox",
                                name: "Checkbox",
                                "data-name": "Checkbox",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setFilter(language, "languages")
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "filter-checkbox-label w-form-label",
                                attrs: { for: "Checkbox" },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(language.name) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "filter-done" }, [
                        _c(
                          "a",
                          {
                            staticClass: "filter-done-button w-inline-block",
                            attrs: { href: "#" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "text-block-62",
                                on: {
                                  click: function ($event) {
                                    return _vm.filterItems({
                                      filterName: "languages",
                                      filterSelectedName: "selectedLanguages",
                                    })
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.translate("Done", _vm.translates)
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "clear-all-btn", on: { click: _vm.clearFilters } },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm.translate("Clear all", _vm.translates)) +
              "\n        "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }