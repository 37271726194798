var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-observer", { ref: "observer" }, [
    _c("div", { staticClass: "vacancies-search-form-block w-form" }, [
      _c(
        "form",
        {
          staticClass: "vacancies-search-form",
          attrs: { name: "search" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitSearch.apply(null, arguments)
            },
            click: function ($event) {
              _vm.isMobile() ? _vm.checkInputClick() : ""
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "input-search-block" },
            [
              _c("validation-provider", {
                staticClass: "input-wrapper",
                attrs: { rules: { minQuery: 2 } },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.query,
                              expression: "query",
                              modifiers: { trim: true },
                            },
                          ],
                          ref: "searchInput",
                          staticClass: "search-input w-input",
                          class: { "has-error": errors[0] },
                          attrs: {
                            id: "search-2",
                            placeholder: _vm.translate(
                              "Search vacancies",
                              _vm.translates
                            ),
                            name: "query",
                            type: "search",
                            autocomplete: "off",
                            readonly: _vm.inputSearchDisabled,
                          },
                          domProps: { value: _vm.query },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.submitSearch.apply(null, arguments)
                            },
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.query = $event.target.value.trim()
                              },
                              _vm.setQueryTimeout,
                            ],
                            click: function ($event) {
                              return _vm.checkInputClick()
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _vm._v(" "),
                        errors[0] && !_vm.isMobile()
                          ? _c(
                              "span",
                              { staticClass: "validation-error-text" },
                              [
                                _c("img", {
                                  attrs: { alt: "", src: "/img/vector.svg" },
                                }),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.translate(errors[0], _vm.translates)
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "search-button w-button",
                  attrs: { type: "submit" },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.translate("Search", _vm.translates)) +
                      "\n                "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "search_list-wrapper" }, [
        _vm.showSearchClue
          ? _c("div", { staticClass: "search-list" }, [
              _vm.searchClue
                ? _c(
                    "ul",
                    _vm._l(_vm.searchClue, function (clueItem) {
                      return _c(
                        "li",
                        {
                          key: clueItem.id,
                          on: {
                            click: function ($event) {
                              return _vm.selectHint(clueItem)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(clueItem.name) +
                              "\n                        "
                          ),
                          _c("span", {
                            staticClass: "vacancy_link",
                            on: {
                              click: function ($event) {
                                return _vm.selectHint(clueItem)
                              },
                            },
                          }),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.showIsMobile
        ? _c("div", { staticClass: "search_mobile_list-wrapper" }, [
            _c("div", { staticClass: "search-mobile-list" }, [
              _c(
                "form",
                {
                  attrs: { name: "searchMobile" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.query,
                        expression: "query",
                        modifiers: { trim: true },
                      },
                    ],
                    ref: "mobileSearchInput",
                    staticClass: "search-input w-input",
                    attrs: {
                      id: "search-mobile",
                      placeholder: _vm.translate(
                        "Search vacancies",
                        _vm.translates
                      ),
                      name: "query",
                      type: "search",
                      autocomplete: "off",
                    },
                    domProps: { value: _vm.query },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.submitSearch.apply(null, arguments)
                      },
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.query = $event.target.value.trim()
                        },
                        _vm.setQueryTimeout,
                      ],
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "back_link",
                    on: {
                      click: function ($event) {
                        return _vm.backSearchClueList()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.query
                    ? _c("span", {
                        staticClass: "clean_link",
                        on: {
                          click: function ($event) {
                            return _vm.cleanSearchClueList()
                          },
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(_vm.searchClue, function (clueItem) {
                  return _c(
                    "li",
                    {
                      key: clueItem.id,
                      on: {
                        click: function ($event) {
                          return _vm.selectHint(clueItem)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(clueItem.name) +
                          "\n                        "
                      ),
                      _c("span", {
                        staticClass: "vacancy_link",
                        on: {
                          click: function ($event) {
                            return _vm.selectHint(clueItem)
                          },
                        },
                      }),
                    ]
                  )
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }