<template>
    <validation-observer
        ref="observer"
        class="w-full in-block"
    >
        <form
            v-show="!sendSuccess"
            id="form-vacancy"
            name="form-vacancy"
            data-name="Form vacancy"
            method="post"
            class="form"
        >
            <div class="form-fields">
                <CustomInput
                    v-model="vacancyForm.username"
                    input-id="username"
                    label="Full name"
                    :type="InputTypes.name"
                    :texts="texts"
                    class="field-container"
                    :is-required="true"
                    placeholder="Full name"
                />
                <div
                    v-if="locale !== 'bg'"
                    class="field-container"
                >
                    <label
                        for="field-7"
                        class="form-label"
                    >
                        {{ translate("Country", translates) }}<span class="text-span-21" />
                    </label>
                    <CustomDropdown
                        v-model="vacancyForm.country"
                        dropdown-id="locationsDropdown"
                        :texts="texts"
                        :options="countries"
                    />
                </div>
                <CustomInput
                    v-model="vacancyForm.email"
                    input-id="email"
                    label="Email"
                    :type="InputTypes.email"
                    :texts="texts"
                    class="field-container"
                    :is-required="true"
                    placeholder="hello@example.com"
                />
                <CustomInput
                    ref="phoneField"
                    v-model="vacancyForm.phone"
                    input-id="phone"
                    label="Phone number"
                    :type="InputTypes.phone"
                    :texts="texts"
                    class="field-container"
                    :is-required="true"
                />
                <CustomInput
                    v-model="vacancyForm.salary"
                    input-id="salary"
                    label="Salary expectations per month"
                    :type="InputTypes.salary"
                    :texts="texts"
                    class="field-container"
                    :is-required="true"
                />
                <CustomInput 
                    v-model="vacancyForm.messenger"
                    input-id="messenger"
                    :label="translate('Telegram', translates)"
                    :type="InputTypes.text"
                    :texts="texts"
                    class="field-container"
                    :is-required="false"
                />
                <CustomInput 
                    v-model="vacancyForm.comment"
                    input-id="comment"
                    :label="translate('Leave your comment', translates)"
                    :type="InputTypes.textarea"
                    :texts="texts"
                    class="field-container"
                    label-classes="align-self-start"
                />
                <UploadCV
                    :texts="texts"
                    class="field-container"
                    :csrf="csrf"
                    @update-file-name="updateFileName"
                />
            </div>
            <div
                v-if="errorMessage"
                class="form-error-message"
            >
                {{ errorMessage }}
            </div>
            <div class="grid-17">
                <validation-provider
                    v-slot="{ errors }"
                    name="checkbox"
                    :rules="{ isEqual: true }"
                    class="position-relative-vue checkbox-field-3"
                >
                    <label class="w-checkbox checkbox-agree custom-checkbox">
                        <div class="w-checkbox-input w-checkbox-input--inputType-custom form-checkbox" />
                        <input
                            id="confirmation-2"
                            v-model="agreement"
                            type="checkbox"
                            name="confirmation-2"
                            data-name="Confirmation 2"
                            style="opacity:0;position:absolute;z-index:-1"
                        ><span
                            class="form-checkbox-label required w-form-label"
                            for="confirmation-2"
                        >{{ translate('I agree to the processing of personal data', translates) }}</span>
                    </label>
                    <span
                        v-if="errors[0]"
                        class="validation-error-text"
                    >
                        {{ translate('This is a required field', translates) }}
                    </span>
                </validation-provider>
                <button
                    type="submit"
                    data-wait="Please wait..."
                    class="form-submit-button w-button"
                    @click.prevent="sendForm"
                >
                    {{ translate('Submit a request', translates) }}
                </button>
            </div>
        </form>
    </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import HiringRepository from '../../../repositories/hiring';
import LocationRepository from '../../../repositories/settings/locations';
import CustomInput from '../components/CustomInput.vue';
import { InputTypes } from '../constants/inputTypes';
import PageHelper from '../../../helpers/page-helper';
import UploadCV from '../components/UploadCV.vue';
import { translate } from '../helpers/translate';
import CustomDropdown from '../components/CustomDropdown.vue';

export default {
    name: 'FormWithoutVacancy',
    components: {
        ValidationObserver,
        ValidationProvider,
        CustomInput,
        UploadCV,
        CustomDropdown
    },
    props: {
        csrf: {
            type: String,
            required: true
        },
        vacancyProp: {
            type: String,
            default: ''
        },
        texts: {
            type: String,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        locale: {
            type: String,
            default: 'en'
        }
    },
    data: function() {
        return {
            agreement: false,
            translates: [],
            sendSuccess: false,
            countries: [],
            vacancyForm: {
                username: '',
                email: '',
                phone: '',
                country: '',
                salary: '',
                messenger: '',
                comment: '',
                fileName: ''
            },
            errorMessage: ''
        };
    },
    computed: {
        vacancy() {
            return JSON.parse(this.vacancyProp);
        }
    },
    mounted() {
        this.translates = JSON.parse(this.texts);
        LocationRepository.getHiringCountriesList(this.csrf).then((response) => {
            this.countries = response.data;
        });
    },
    created(){
        this.InputTypes = InputTypes;
    },
    methods: {
        translate,
        updateFileName(fileName){
            this.vacancyForm.fileName = fileName;
        },
        resetVacancyForm(){
            this.vacancyForm.username = '';
            this.vacancyForm.email = '';
            this.vacancyForm.phone = '';
            const phoneFieldRef = this.$refs.phoneField;
            phoneFieldRef.clearPhone();
            this.vacancyForm.country = '';
            this.vacancyForm.salary = '';
            this.vacancyForm.messenger = '';
            this.vacancyForm.comment = '';
            this.vacancyForm.fileName = '';
            this.$refs.observer.reset();
        },
        sendForm() {
            this.$refs.observer.validate().then((isValid) => {
                if (!isValid) {
                    let errorMessageString = '';
                    for(let errorItem in this.$refs.observer.errors) {
                        let errorMessages = this.$refs.observer.errors[errorItem];
                        if (!errorMessages.length) {
                            continue;
                        }
                        errorMessageString += errorItem + '-' + errorMessages.join(',') + ';';
                    }
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'vacancyForm_Sent_Error',
                        'deviceID': PageHelper.getDeviceID(),
                        'language': this.language,
                        'errorText': errorMessageString,
                        'vacancy': this.vacancy.name,
                    });

                    return;
                }

                HiringRepository.sendForm({
                    username: this.vacancyForm.username.trim(),
                    email: this.vacancyForm.email,
                    phone: this.vacancyForm.phone,
                    salary: this.vacancyForm.salary,
                    filename: this.vacancyForm.fileName,
                    country: this.locale === 'bg' ? 'България' : this.vacancyForm.country,
                    messenger: this.vacancyForm.messenger,
                    comment: this.vacancyForm.comment,
                    vacancy_id: this.vacancy.id
                }, this.csrf).then(() => {  
                    const darkBackground = document.querySelector('.dark-bg');
                    const customSuccessMessage = document.querySelector('#custom-success-message');
                    darkBackground.style.display = 'block';
                    customSuccessMessage.style.display = 'block';
                    this.resetVacancyForm();

                    const dataLayer = {
                        'event': 'vacancyFormSentSuccess',
                        'vacancy': this.vacancy.name,
                        'deviceID': PageHelper.getDeviceID(),
                        'language': this.language
                    };

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(dataLayer);
                }).catch((error) => {
                    this.errorMessage = error.response.data.message;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'vacancyForm_Sent_Error',
                        'deviceID': PageHelper.getDeviceID(),
                        'language': this.language,
                        'errorText': error.data.message,
                        'vacancy': this.vacancy.name
                    });
                });
            });
        },
    }
};
</script>

<style scoped lang="scss">
.field-container {
  display: grid;
  grid-template-columns: 1fr 360px;
  margin-bottom: 16px;
  align-items: center;
  gap: 16px;
}

@media screen and (max-width: 767px) {
  .field-container {
    grid-template-columns: 1fr 300px;
  }
}

@media screen and (max-width: 480px) {
  .field-container {
    grid-template-columns: 1fr;
    margin: 0;
    gap: 0;
  }
  
  .form-submit-button {
    margin-top: 8px;
  }
}

.validation-error-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
    font-size: 16px;
    line-height: 24px;
    vertical-align: top;
    background: none;
    border-radius: 4px;
    color: rgba(234, 89, 107, 1);
    font-weight: 500;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.13);
}


.required::after {
    content: ' *';
    color: #7A9FFF;
}

.form {
  &-section {
    display: flex;
  }

  &-checkbox {
    margin: 0;
  }

  &-checkbox-label {
    width: fit-content;
    margin-left: 12px;
  }

  &-submit-button {
    font-weight: 600;
    line-height: 18px;
  }

  &-select-option {
    display: block;
    padding: 12px 12px 12px 16px;
    color: rgba(236, 241, 255, 1);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  
  &-label {
    padding-top: 0;
  }

  &-error-message {
    margin-top: 16px;
    color: rgba(234, 89, 107, 1);
  }
}

.grid-17 {
  display: grid;
}

@media screen and (max-width: 767px) {
  .grid-17 {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    justify-content: center;
  }
}

.checkbox-agree {
  padding: 0;
  grid-column-gap: 0;
}

.input-wrapper {
    position: relative;
}

.error-button {
    position: absolute;
    right: 7px;
    top: 12px;
}

.w-full{
    width: 100%;
}

.in-block{
    display: inline-block;
}

.width-full{
    width: 100%;
    max-width: 372px;
}
</style>