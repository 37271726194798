var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "form-field form-field-select w-dropdown",
        attrs: { "data-hover": "false", "data-delay": "0" },
      },
      [
        _c(
          "div",
          {
            staticClass: "dropdown-toggle-7 w-dropdown-toggle",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.toggleDropdown.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "text-block-66" }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.currentValueTitle ||
                      _vm.translate(_vm.firstItemText, _vm.translates)
                  ) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c(
              "nav",
              {
                ref: _vm.dropdownId,
                staticClass: "popup-dropdown-wrapper w-dropdown-list",
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-link-6 w-dropdown-link",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.selectOption("")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.translate(_vm.firstItemText, _vm.translates)
                        ) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.options, function (option) {
                  return _c(
                    "a",
                    {
                      key: option.id || option.code,
                      staticClass: "dropdown-link-6 w-dropdown-link",
                      class: {
                        "dropdown-link-selected":
                          _vm.currentValueTitle === option.name,
                      },
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.selectOption(option.id || option.code)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(option.name) +
                          "\n                "
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "icon-13 w-icon-dropdown-toggle" }),
          ]
        ),
        _vm._v(" "),
        _c(
          "nav",
          {
            ref: _vm.dropdownId,
            staticClass: "popup-dropdown-wrapper w-dropdown-list",
          },
          [
            _c(
              "a",
              {
                staticClass: "dropdown-link-6 w-dropdown-link",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.selectOption("")
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.translate(_vm.firstItemText, _vm.translates)) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.options, function (option) {
              return _c(
                "a",
                {
                  key: option.id || option.code,
                  staticClass: "dropdown-link-6 w-dropdown-link",
                  class: {
                    "dropdown-link-selected":
                      _vm.currentValueTitle === option.name,
                  },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.selectOption(option.id || option.code)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(option.name) +
                      "\n            "
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }