var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "vacancies-list-replace" },
    [
      _c("vacancy-search-form", {
        attrs: { csrf: _vm.csrf, texts: _vm.texts, locale: _vm.locale },
        on: { submitSearch: _vm.submitSearch },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "container-18 w-container" }, [
        _c("div", { staticClass: "div-block-14 priority-top" }, [
          _c(
            "div",
            {
              staticClass: "dropdown-5 w-dropdown",
              attrs: { "data-hover": "false", "data-delay": "0" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "dropdown-toggle-4 w-dropdown-toggle",
                  attrs: {
                    id: "w-dropdown-toggle-1",
                    "aria-controls": "w-dropdown-list-1",
                    "aria-haspopup": "menu",
                    "aria-expanded": "false",
                    role: "button",
                    tabindex: "0",
                  },
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-block-26" }, [
                    _c("span", { staticClass: "text-span-10" }, [
                      _vm._v(_vm._s(_vm.translate("Direction"))),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "nav",
                {
                  staticClass: "dropdown-list-16 w-dropdown-list",
                  attrs: {
                    id: "w-dropdown-list-1",
                    "aria-labelledby": "w-dropdown-toggle-1",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mobile-panel",
                      on: {
                        click: function ($event) {
                          return _vm.closeDropdown("#w-dropdown-toggle-1")
                        },
                        touchstart: _vm.swipe,
                        touchmove: _vm.swipeDetect,
                      },
                    },
                    [
                      _c("div", { staticClass: "tap-mobile-panel" }),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.translate("Choose direction")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mobile-list" },
                    _vm._l(_vm.directions, function (direction) {
                      return _c(
                        "div",
                        {
                          key: direction.id,
                          staticClass: "filter-item",
                          on: {
                            click: function ($event) {
                              direction.checked = !direction.checked
                              _vm.setFiltersState(direction, "Direction")
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "checkbox-filter check-box-outline-blank",
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "container-checkbox" },
                                [
                                  _c("input", {
                                    attrs: { type: "checkbox" },
                                    domProps: { checked: direction.checked },
                                    on: {
                                      click: function ($event) {
                                        direction.checked = !direction.checked
                                        _vm.setFiltersState(
                                          direction,
                                          "Direction"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-block-27" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(direction.name) +
                                "\n                            "
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown-13 w-dropdown",
              attrs: { "data-hover": "false", "data-delay": "0" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "dropdown-toggle-4 w-dropdown-toggle",
                  attrs: {
                    id: "w-dropdown-toggle-2",
                    "aria-controls": "w-dropdown-list-2",
                    "aria-haspopup": "menu",
                    "aria-expanded": "false",
                    role: "button",
                    tabindex: "0",
                  },
                },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-block-26" }, [
                    _c("span", { staticClass: "text-span-10" }, [
                      _vm._v(_vm._s(_vm.translate("Location"))),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "nav",
                {
                  staticClass: "_2-list-filter w-dropdown-list",
                  attrs: {
                    id: "w-dropdown-list-2",
                    "aria-labelledby": "w-dropdown-toggle-2",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mobile-panel",
                      on: {
                        click: function ($event) {
                          return _vm.closeDropdown("#w-dropdown-toggle-2")
                        },
                        touchstart: _vm.swipe,
                        touchmove: _vm.swipeDetect,
                      },
                    },
                    [
                      _c("div", { staticClass: "tap-mobile-panel" }),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.translate("Choose location")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mobile-list" },
                    _vm._l(_vm.locations, function (location) {
                      return _c(
                        "div",
                        {
                          key: location.id,
                          staticClass: "filter-item",
                          on: {
                            click: function ($event) {
                              location.checked = !location.checked
                              _vm.setFiltersState(location, "Location")
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "checkbox-filter check-box-outline-blank",
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "container-checkbox" },
                                [
                                  _c("input", {
                                    attrs: { type: "checkbox" },
                                    domProps: { checked: location.checked },
                                    on: {
                                      click: function ($event) {
                                        location.checked = !location.checked
                                        _vm.setFiltersState(
                                          location,
                                          "Location"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-block-27" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(location.name) +
                                "\n                            "
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown-13 w-dropdown",
              attrs: { "data-hover": "false", "data-delay": "0" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "dropdown-toggle-4 w-dropdown-toggle",
                  attrs: {
                    id: "w-dropdown-toggle-3",
                    "aria-controls": "w-dropdown-list-3",
                    "aria-haspopup": "menu",
                    "aria-expanded": "false",
                    role: "button",
                    tabindex: "0",
                  },
                },
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-block-26" }, [
                    _c("span", { staticClass: "text-span-10" }, [
                      _vm._v(_vm._s(_vm.translate("Company"))),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "nav",
                {
                  staticClass: "dropdown-list-11 w-dropdown-list",
                  attrs: {
                    id: "w-dropdown-list-3",
                    "aria-labelledby": "w-dropdown-toggle-3",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mobile-panel",
                      on: {
                        click: function ($event) {
                          return _vm.closeDropdown("#w-dropdown-toggle-3")
                        },
                        touchstart: _vm.swipe,
                        touchmove: _vm.swipeDetect,
                      },
                    },
                    [
                      _c("div", { staticClass: "tap-mobile-panel" }),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.translate("Choose company")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mobile-list" },
                    _vm._l(_vm.companies, function (company) {
                      return _c(
                        "div",
                        {
                          key: company.id,
                          staticClass: "filter-item",
                          on: {
                            click: function ($event) {
                              company.checked = !company.checked
                              _vm.setFiltersState(company, "Company")
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "checkbox-filter check-box-outline-blank",
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "container-checkbox" },
                                [
                                  _c("input", {
                                    attrs: { type: "checkbox" },
                                    domProps: { checked: company.checked },
                                    on: {
                                      click: function ($event) {
                                        company.checked = !company.checked
                                        _vm.setFiltersState(company, "Company")
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-block-27" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(company.name) +
                                "\n                            "
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown-13 w-dropdown",
              attrs: { "data-hover": "false", "data-delay": "0" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "dropdown-toggle-4 w-dropdown-toggle",
                  attrs: {
                    id: "w-dropdown-toggle-4",
                    "aria-controls": "w-dropdown-list-4",
                    "aria-haspopup": "menu",
                    "aria-expanded": "false",
                    role: "button",
                    tabindex: "0",
                  },
                },
                [
                  _vm._m(3),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-block-26" }, [
                    _c("span", { staticClass: "text-span-10" }, [
                      _vm._v(_vm._s(_vm.translate("Level"))),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "nav",
                {
                  staticClass: "dropdown-list-19 w-dropdown-list",
                  attrs: {
                    id: "w-dropdown-list-4",
                    "aria-labelledby": "w-dropdown-toggle-4",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mobile-panel",
                      on: {
                        click: function ($event) {
                          return _vm.closeDropdown("#w-dropdown-toggle-4")
                        },
                        touchstart: _vm.swipe,
                        touchmove: _vm.swipeDetect,
                      },
                    },
                    [
                      _c("div", { staticClass: "tap-mobile-panel" }),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.translate("Choose level")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mobile-list" },
                    _vm._l(_vm.levels, function (level) {
                      return _c(
                        "div",
                        {
                          key: level.id,
                          staticClass: "filter-item",
                          on: {
                            click: function ($event) {
                              level.checked = !level.checked
                              _vm.setFiltersState(level, "Level")
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "checkbox-filter check-box-outline-blank",
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "container-checkbox" },
                                [
                                  _c("input", {
                                    attrs: { type: "checkbox" },
                                    domProps: { checked: level.checked },
                                    on: {
                                      click: function ($event) {
                                        level.checked = !level.checked
                                        _vm.setFiltersState(level, "Level")
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-block-27" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(level.name) +
                                "\n                            "
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.showFilterLang.includes(_vm.locale)
            ? _c(
                "div",
                {
                  staticClass: "dropdown-13 w-dropdown",
                  attrs: { "data-hover": "false", "data-delay": "0" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-toggle-4 w-dropdown-toggle",
                      attrs: {
                        id: "w-dropdown-toggle-5",
                        "aria-controls": "w-dropdown-list-5",
                        "aria-haspopup": "menu",
                        "aria-expanded": "false",
                        role: "button",
                        tabindex: "0",
                      },
                    },
                    [
                      _vm._m(4),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-block-26" }, [
                        _c("span", { staticClass: "text-span-10" }, [
                          _vm._v(_vm._s(_vm.translate("Language"))),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "nav",
                    {
                      staticClass: "dropdown-list-19 w-dropdown-list",
                      attrs: {
                        id: "w-dropdown-list-5",
                        "aria-labelledby": "w-dropdown-toggle-5",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mobile-panel",
                          on: {
                            click: function ($event) {
                              return _vm.closeDropdown("#w-dropdown-toggle-5")
                            },
                            touchstart: _vm.swipe,
                            touchmove: _vm.swipeDetect,
                          },
                        },
                        [
                          _c("div", { staticClass: "tap-mobile-panel" }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.translate("Choose language")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mobile-list" },
                        _vm._l(_vm.languages, function (language) {
                          return _c(
                            "div",
                            {
                              key: language.id,
                              staticClass: "filter-item",
                              on: {
                                click: function ($event) {
                                  language.checked = !language.checked
                                  _vm.setFiltersState(language, "Language")
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "checkbox-filter check-box-outline-blank",
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "container-checkbox" },
                                    [
                                      _c("input", {
                                        attrs: { type: "checkbox" },
                                        domProps: { checked: language.checked },
                                        on: {
                                          click: function ($event) {
                                            language.checked = !language.checked
                                            _vm.setFiltersState(
                                              language,
                                              "Language"
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "checkmark" }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "text-block-27" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(language.name) +
                                    "\n                            "
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container-16 w-container" }, [
        _c("h2", { staticClass: "title" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.translate("Vacancies")) + "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-block-28" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.vacancies.length) + "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container-17 w-container priority-0" }, [
        _c(
          "div",
          { staticClass: "div-block-28" },
          [
            _vm._l(_vm.filtersItems, function (filter) {
              return _c(
                "div",
                { key: filter.slug, staticClass: "div-block-27" },
                [
                  _c("div", { staticClass: "text-block-31" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(filter.name) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "close",
                    attrs: {
                      src: "/webflow-main/images/Close.svg",
                      height: "20",
                      width: "20",
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.removeFilter(filter)
                      },
                    },
                  }),
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.filtersItems.length,
                    expression: "filtersItems.length",
                  },
                ],
                staticClass: "button-8 w-button",
                on: { click: _vm.clearFilter },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.translate("Clear all")) +
                    "\n            "
                ),
              ]
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("vacancy-list-item", {
        attrs: {
          vacancies: _vm.vacancies,
          texts: _vm.texts,
          "vacancies-not-found": _vm.vacanciesNotFound,
          "show-language-block": _vm.showFilterLang.includes(_vm.locale),
          "bonus-btn": _vm.bonusBtnProp,
          locale: _vm.locale,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "div-block-13" }, [
      _c("img", {
        attrs: {
          height: "24",
          width: "24",
          src: "/img/arrow-drop-down.svg",
          loading: "lazy",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          height: "24",
          width: "24",
          src: "/img/arrow-drop-down.svg",
          loading: "lazy",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          height: "24",
          width: "24",
          src: "/img/arrow-drop-down.svg",
          loading: "lazy",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          height: "24",
          width: "24",
          src: "/img/arrow-drop-down.svg",
          loading: "lazy",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          height: "24",
          width: "24",
          src: "/img/arrow-drop-down.svg",
          loading: "lazy",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }