<template>
    <div class="input-wrapper">
        <div
            data-hover="false"
            data-delay="0"
            class="form-field form-field-select w-dropdown"
        >
            <div
                class="dropdown-toggle-7 w-dropdown-toggle"
                @click.stop="toggleDropdown"
            >
                <div class="text-block-66">
                    {{ currentValueTitle || translate(firstItemText, translates) }}
                </div>
                <nav
                    :ref="dropdownId"
                    class="popup-dropdown-wrapper w-dropdown-list"
                >
                    <a
                        href="#"
                        class="dropdown-link-6 w-dropdown-link"
                        @click="selectOption('')"
                    >
                        {{ translate(firstItemText, translates) }}
                    </a>
                    <a
                        v-for="option in options"
                        :key="option.id || option.code"
                        href="#"
                        class="dropdown-link-6 w-dropdown-link"
                        :class="{'dropdown-link-selected': currentValueTitle === option.name}"
                        @click="selectOption(option.id || option.code)"
                    >
                        {{ option.name }}
                    </a>
                </nav>
                <div class="icon-13 w-icon-dropdown-toggle" />
            </div>
            <nav
                :ref="dropdownId"
                class="popup-dropdown-wrapper w-dropdown-list"
            >
                <a
                    href="#"
                    class="dropdown-link-6 w-dropdown-link"
                    @click="selectOption('')"
                >
                    {{ translate(firstItemText, translates) }}
                </a>
                <a
                    v-for="option in options"
                    :key="option.id || option.code"
                    href="#"
                    class="dropdown-link-6 w-dropdown-link"
                    :class="{'dropdown-link-selected': currentValueTitle === option.name}"
                    @click="selectOption(option.id || option.code)"
                >
                    {{ option.name }}
                </a>
            </nav>
        </div>
    </div>
</template>

<script>
import { translate } from '../helpers/translate';

export default {
    props: {
        dropdownId: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        },
        texts: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: true
        },
        firstItemText: {
            type: String,
            required: false,
            default: 'Select one...'
        }
    },
    data: () => ({
        translates: []
    }),
    computed: {
        currentValueTitle(){
            const currentOption = this.$props.options.find(item => {
                if (item.id?.toString() === this.$props.value || item?.code === this.$props.value) {
                    return true;
                }
            });
            if (currentOption) {
                return currentOption.name;
            }

            return '';
        }
    },
    mounted(){
        document.addEventListener('click', event => {
            const filterRef = this.$refs[this.$props.dropdownId];
            const isDropdownListOpen = filterRef.classList.contains('w--open');
            const isClickOutsideDropdown = !filterRef.contains(event.target);
            const isValueSelected = this.$props.value;

            if (isValueSelected) {
                this.hideDropdown();
                return;
            }
            if (isDropdownListOpen && isClickOutsideDropdown) {
                this.$emit('input', '');
                this.hideDropdown();
            }
        });
        this.translates = JSON.parse(this.texts);
    },
    methods: {
        selectOption(optionId){
            this.$emit('input', optionId.toString());
            this.hideDropdown();
        },
        toggleDropdown(){
            const isOpen = this.$refs[this.$props.dropdownId].classList.contains('w--open');

            if (isOpen) {
                this.hideDropdown();
            } else {
                this.showDropdown();
            }
        },
        showDropdown(){
            this.$refs[this.$props.dropdownId].classList.add('w--open');
        },
        hideDropdown(){
            this.$refs[this.$props.dropdownId].classList.remove('w--open');
        },
        translate
    }
};
</script>
<style scoped lang="scss">
.dropdown-link-selected {
  background-color: rgba(0, 4, 16, 0.34);
}

.popup-dropdown-wrapper {
  overflow-y: scroll;
}

.form-field-select {
    margin-left: 0;
    margin-right: 0;
}

.text-block-66 {
    overflow: hidden;
}

.dropdown-link-6 {
    white-space: normal;
}

.input-wrapper {
  display: flex;
  position: relative;
}

@media screen and (max-width: 479px) {
  .input-wrapper {
    margin-bottom: 0;
  }

  .form-field-select {
    margin-bottom: 25px;
  }
}
</style>