<template>
    <div class="job-card"
         @click.prevent="sendEventToDataLayer()"
    >
        <a class="job-wrapper">
            <div class="ellipse" />
            <div class="job-information">
                <div class="job-title">
                    <h3 class="job-title-text">
                        {{ vacancy.is_hot ? '🔥' : '' }}
                        {{ vacancy.name }}
                    </h3>
                </div>
                <div class="info-block">
                    <div
                        ref="filtersContainer"
                        class="filters-block"
                    >
                        <div class="w-layout-grid fillters m-bottom">
                            <div class="job-filter">
                                <div class="filter-text">
                                    {{ vacancy.skill_name }}
                                </div>
                            </div>
                            <div
                                v-if="locale !== 'ru' && locale !== 'bg'"
                                class="job-filter"
                            >
                                <img
                                    v-if="vacancy.language_code === 'ua' || vacancy.language_code === 'en'"
                                    src="/webflow-main/images/globe.svg"
                                    loading="lazy"
                                    alt=""
                                    class="globe"
                                >
                                <div class="filter-text">
                                    {{ vacancy.language_name }}
                                </div>
                            </div>
                        </div>
                        <div class="w-layout-grid fillters filters-locations">
                            <div
                                v-for="location in vacancy.locations"
                                :key="location.id"
                                ref="locationFilter"
                            >
                                <div
                                    v-if="location.location" 
                                    class="job-filter"
                                >
                                    <div class="filter-text">
                                        {{ location.location }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="job-logo-wrapper">
                        <img
                            :src="companyLogoLink"
                            loading="lazy"
                            alt=""
                            class="job-logo"
                        >
                        <a
                            v-if="bonusBtnProp && vacancy.bonus"
                            href="#"
                            class="referral-card-button w-button"
                        >{{ vacancy.bonus }}{{ vacancy.language_code === 'bg' ? ' лв.' : '$'}}</a>
                        <a
                            v-else
                            href="#"
                            class="job-link w-inline-block"
                        >
                            <img
                                src="/webflow-main/images/arrow.svg"
                                loading="lazy"
                                width="24"
                                height="24"
                                alt=""
                            ></a>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        locale: {
            type: String,
            default: 'en'
        },
        index: {
            type: [Number, String],
            required: true,
        },
        vacancyProp: {
            type: [Object, String],
            required: true
        },
        bonusBtnProp: {
            type: [Boolean, Number],
            default: false
        },
        dataLayerEvent: {
            type: String,
            default: ''
        }
    },
    computed: {
        vacancy() {
            if (typeof this.vacancyProp === 'string'){
                return JSON.parse(this.vacancyProp);
            }
            return this.vacancyProp;
        },
        companyLogoLink() {
            if (this.vacancy.company_logo_exist) {
                return `/media/cache/company_logo/company/${this.vacancy.company_slug}.webp`;
            }

            return '/img/netpeak-group-base-logo.webp';
        }
    },
    mounted(){
        this.makeFilterElipsis();
    },
    methods: {
        sendEventToDataLayer() {
            let dataLayer = {
                'event': this.dataLayerEvent,
                'language': this.locale
            };

            if (this.dataLayerEvent === 'VacancyListClick'){
                dataLayer.position = parseInt(this.index) + 1;
                dataLayer.vacancy = this.vacancy.name;
            } else {
                dataLayer.linkClick = this.vacancy.name;
            }
            
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(dataLayer);
            window.location.href = `/${this.vacancy.language_code}/vacancy/${this.vacancy.slug}/`;
        },
        makeFilterElipsis(){
            const maxWidth = this.$refs.filtersContainer.offsetWidth;
            const locationFilters = this.$refs.locationFilter;
            let filtersWidth = 0;
            for (let i = 0; i < locationFilters.length; i++) {
                const filter = locationFilters[i];
                const nextFilter = locationFilters[i+1];
                filtersWidth += filter.offsetWidth;
                if (filtersWidth >= maxWidth) {
                    filter.getElementsByClassName('filter-text')[0].innerText = '...';
                    if (nextFilter) {
                        nextFilter.style.visibility = 'hidden';
                    }
                    return;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .hot-tab {
        background-color: transparent;
    }
    .card-title {
        text-transform: none;
    }
    .job-wrapper {
        text-decoration: none;
    } 
</style>