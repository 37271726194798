<template>
    <validation-observer
        ref="observer"
    >
        <div class="vacancies-search-form-block w-form">
            <form
                name="search"
                class="vacancies-search-form"
                @submit.prevent="submitSearch"
                @click="isMobile() ? checkInputClick() : ''"
            >
                <div class="input-search-block">
                    <validation-provider
                        v-slot="{ errors }"
                        class="input-wrapper"
                        :rules="{ minQuery: 2 }"
                    >
                        <input
                            id="search-2"
                            ref="searchInput"
                            v-model.trim="query"
                            :class="{'has-error': errors[0]}"
                            :placeholder="translate('Search vacancies', translates)"
                            class="search-input w-input"
                            name="query"
                            type="search"
                            autocomplete="off"
                            :readonly="inputSearchDisabled"
                            @keyup.enter="submitSearch"
                            @input="setQueryTimeout"
                            @click="checkInputClick()"
                        >

                        <span
                            v-if="errors[0] && !isMobile()"
                            class="validation-error-text"
                        >
                            <img
                                alt=""
                                src="/img/vector.svg"
                            >
                            {{ translate(errors[0], translates) }}
                        </span>
                    </validation-provider>

                    <button
                        class="search-button w-button"
                        type="submit"
                    >
                        {{ translate('Search', translates) }}
                    </button>
                </div>
            </form>
            <div class="search_list-wrapper">
                <div
                    v-if="showSearchClue"
                    class="search-list"
                >
                    <ul v-if="searchClue">
                        <li
                            v-for="clueItem in searchClue"
                            :key="clueItem.id"
                            @click="selectHint(clueItem)"
                        >
                            {{ clueItem.name }}
                            <span
                                class="vacancy_link"
                                @click="selectHint(clueItem)"
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <div
                v-if="showIsMobile"
                class="search_mobile_list-wrapper"
            >
                <div
                    class="search-mobile-list"
                >
                    <form
                        name="searchMobile"
                        @submit.prevent=""
                    >
                        <input
                            id="search-mobile"
                            ref="mobileSearchInput"
                            v-model.trim="query"
                            :placeholder="translate('Search vacancies', translates)"
                            class="search-input w-input"
                            name="query"
                            type="search"
                            autocomplete="off"
                            @keyup.enter="submitSearch"
                            @input="setQueryTimeout"
                        >
                        <span
                            class="back_link"
                            @click="backSearchClueList()"
                        />
                        <span
                            v-if="query"
                            class="clean_link"
                            @click="cleanSearchClueList()"
                        />
                    </form>
                    <ul>
                        <li
                            v-for="clueItem in searchClue"
                            :key="clueItem.id"
                            @click="selectHint(clueItem)"
                        >
                            {{ clueItem.name }}
                            <span
                                class="vacancy_link"
                                @click="selectHint(clueItem)"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </validation-observer>
</template>

<script>
import VacancyRepository from '../../../../repositories/vacancies';
import { extend, ValidationObserver, ValidationProvider} from 'vee-validate';
import {min} from 'vee-validate/dist/rules';
import { translate } from '../../helpers/translate';
import { isMobile, isMobileAndroid } from '../../helpers/isMobile';

extend('minQuery', {
    ...min,
    message: 'Refine your search criteria',
});
export default {
    name: 'VacancySearchForm',
    components: {
        ValidationObserver,
        ValidationProvider
    },
    props: {
        'locale': {
            'type': String,
            'default': 'en'
        },
        'csrf': {
            'type': String,
            'default': ''
        },
        'texts': {
            'type': String,
            'default': ''
        },
        searchValue: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        translates: {},
        query: '',
        searchClue: [],
        showSearchClue: false,
        showIsMobile: false,
        blocked: false,
        inputSearchDisabled: false,
        lastTimerId: 0
    }),
    watch: {
        searchValue: function () {
            this.query = this.searchValue;
        }
    },
    mounted() {
        if (this.$route.query.query) {
            this.query = this.$route.query.query;
        }

        this.translates = JSON.parse(this.texts);
        const searchClueDiv = document.querySelector('.container-15');

        document.addEventListener('click', (e) => {
            const withinBoundaries = e.composedPath().includes(searchClueDiv);

            if (!withinBoundaries) {
                this.showSearchClue = false;
            }
        });

        if(this.isMobile()){
            this.inputSearchDisabled = true;
        }

        window.addEventListener(
            'orientationchange',
            () => {
                location.reload();
            }
        );
    },
    methods: {
        setQueryTimeout($event){
            if(this.isMobileAndroid()){
                this.query = $event.target.value.trim();
            }
            clearTimeout(this.lastTimerId);
            let time = 1200;
            this.lastTimerId = setTimeout(this.searchClueVacancies, time);
            this.$emit('update-search-value', this.query);
        },
        checkInputClick(){
            if(this.isMobile()){
                this.showIsMobile = true;
            }

            if(this.searchClue.length > 0 && this.query.length > 2){
                this.showSearchClue = true;
            }
        },
        searchClueVacancies() {
            if (this.query.length < 2) {
                this.showSearchClue = false;
                this.searchClue = [];
                return;
            }

            if (this.blocked) {
                return;
            }

            this.blocked = true;
            VacancyRepository.searchClues({
                locale: this.locale,
                query: this.query,
                clues: 1
            }, this.csrf).then((response) => {
                this.searchClue = this.query.length > 1 ? response.data : [];
                this.showSearchClue = this.searchClue.length > 0 && this.query.length !== 0;
                this.blocked = false;
                this.submitFlag = false;
            }).catch(() => {
                this.showSearchClue = false;
                this.searchClue = [];
                this.blocked = false;
                this.submitFlag = false;
            });
        },
        selectHint(item) {
            this.query = item.name;
            clearTimeout(this.lastTimerId);
            if (this.isMobile()) {
                this.showIsMobile = false;
            }
            this.showSearchClue = false;
            this.searchClue = [];
            this.$emit('submitSearch', {
                query: this.query,
                dataLayer: {
                    event: 'SearchInteraction',
                    searchQuery: this.query
                }
            });
        },
        submitSearch() {
            clearTimeout(this.lastTimerId);

            if (this.isMobile()) {
                this.showIsMobile = false;
            }
            this.showSearchClue = false;
            this.searchClue = [];

            const searchPayload = {
                query: this.query,
                dataLayer: {
                    event: 'SearchInteraction',
                    searchQuery: this.query
                }
            };

            this.$emit('submitSearch', searchPayload);
        },
        checkPhone() {
            if (this.isMobile()) {
                this.showIsMobile = true;
            }
        },
        cleanSearchClueList() {
            this.query = '';
            this.searchClue = [];
            this.$emit('submitSearch', this.query);
        },
        backSearchClueList() {
            if(this.query.length < 2){
                this.searchClue = [];
            }
            this.showSearchClue = false;
            this.showIsMobile = false;
        },
        translate,
        isMobile,
        isMobileAndroid
    }
};
</script>

<style lang="scss" scoped>
.vacancies-search-form {
    border-bottom: none;
}

.vacancies-search-form-block {
    margin-bottom: 0;
}

.search-list {
    z-index: 1000;
    position: relative;
    border-radius: 10px;
    width: 100%;
    margin: 2px 0 0;
    border: 1px solid gray;
    color: white;
}

.search_list-wrapper {
    margin-bottom: 16px;
}

.search-list ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.search-list ul li:first-child, .search-list ul li:last-child {
    border-radius: 10px;
}

.search-list ul li {
    padding: 7px 0 7px 16px;
    font-size: 16px;
    line-height: 24px;

}

.search-list ul li:hover {
    background-color: #cacaca;
    cursor: pointer;

}

.text-error {
    text-align: center;
    margin: 5px 0;
    color: red;
}

.validation-error-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 12px 6px 8px;
    min-width: 180px;
    font-size: 14px;
    vertical-align: top;
    top: 60px;
    left: 0;
    position: absolute;
    background: #FFE1E1;
    border-radius: 4px;
    color: #E04444;
    box-shadow: 0 2px 2px -2px gray;

    img {
        padding: 0 5px 2px 0;
        position: relative;
    }
}

.validation-error-text:before {
    content: "";
    position: absolute;
    left: 1.4rem;
    top: -0.4rem;
    width: 0.9rem;
    height: 0.9rem;
    transform: rotate(45deg);
    background: #FFE1E1;
}

.input-wrapper {
    width: 100%;
    position: relative;
}

.search-input {
    margin: 0;
    padding: 0 48px;

    &:read-only {
        background: transparent;
    }
}

@media screen and (max-width: 992px) {
    .search_list-wrapper {
        margin-left: 2%;
        margin-right: 2%;
    }
    .search-list {
        width: 100%;
    }
}

@media screen and (max-width: 479px) {
    input, textarea, select {
        font-size: 16px;
    }
    .search-mobile-list {
        width: 100%;
        height: 100%;
        position: fixed;
        border-radius: unset;
        background-color: rgba(13, 22, 43, 1);
        color: white;
        top: 0;
        left: 0;
        margin: 0;
        overflow: hidden;
        z-index: 9999;
    }
    .search-mobile-list input {
        background-color: rgba(33, 43, 78, 1);
        position: relative;
        margin: 10px 12px;
        width: 95%;
        height: 52px;
        padding-left: 45px;
        padding-right: 45px;
        border-radius: 40px;
        color: white;
        z-index: 100;
    }
    *:disabled {
        background-color: white;
        color: linen;
        opacity: 1;
    }
    .search-mobile-list ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .search-mobile-list ul li {
        padding: 0 0 0 12px;
        font-size: 16px;
        line-height: 44px;
        position: relative;

    }
    .vacancy_link {
        position: absolute;
        top: 0;
        right: 30px;
    }
    .vacancy_link:before {
        position: absolute;
        content: url('/public/img/m_search_vacancy_link.svg');
        color: white;
    }
    .back_link {
        position: absolute;
        top: 0;
        left: 0;
        vertical-align: middle;
        display: inline-block;
        z-index: 1000;
    }
    .back_link:before {
        position: absolute;
        content: url('/public/img/keyboard-arrow-left.svg');
        background-size: cover;
        display: inline-block;
        top: 28px;
        left: 30px;
        vertical-align: middle;
    }
    .clean_link {
        position: absolute;
        top: 0;
        right: 0;
        vertical-align: middle;
        display: inline-block;
        z-index: 1000;
    }
    .clean_link:before {
        position: absolute;
        content: url('/public/img/close-icon.svg');
        background-size: cover;
        display: inline-block;
        top: 28px;
        right: 30px;
        vertical-align: middle;
    }
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        input:focus, textarea:focus, input:focus {
            font-size: 16px;
        }
    }
}
</style>