var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "job-card",
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.sendEventToDataLayer()
        },
      },
    },
    [
      _c("a", { staticClass: "job-wrapper" }, [
        _c("div", { staticClass: "ellipse" }),
        _vm._v(" "),
        _c("div", { staticClass: "job-information" }, [
          _c("div", { staticClass: "job-title" }, [
            _c("h3", { staticClass: "job-title-text" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.vacancy.is_hot ? "🔥" : "") +
                  "\n                    " +
                  _vm._s(_vm.vacancy.name) +
                  "\n                "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info-block" }, [
            _c(
              "div",
              { ref: "filtersContainer", staticClass: "filters-block" },
              [
                _c("div", { staticClass: "w-layout-grid fillters m-bottom" }, [
                  _c("div", { staticClass: "job-filter" }, [
                    _c("div", { staticClass: "filter-text" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.vacancy.skill_name) +
                          "\n                            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.locale !== "ru" && _vm.locale !== "bg"
                    ? _c("div", { staticClass: "job-filter" }, [
                        _vm.vacancy.language_code === "ua" ||
                        _vm.vacancy.language_code === "en"
                          ? _c("img", {
                              staticClass: "globe",
                              attrs: {
                                src: "/webflow-main/images/globe.svg",
                                loading: "lazy",
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "filter-text" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.vacancy.language_name) +
                              "\n                            "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-layout-grid fillters filters-locations" },
                  _vm._l(_vm.vacancy.locations, function (location) {
                    return _c(
                      "div",
                      {
                        key: location.id,
                        ref: "locationFilter",
                        refInFor: true,
                      },
                      [
                        location.location
                          ? _c("div", { staticClass: "job-filter" }, [
                              _c("div", { staticClass: "filter-text" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(location.location) +
                                    "\n                                "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "job-logo-wrapper" }, [
              _c("img", {
                staticClass: "job-logo",
                attrs: { src: _vm.companyLogoLink, loading: "lazy", alt: "" },
              }),
              _vm._v(" "),
              _vm.bonusBtnProp && _vm.vacancy.bonus
                ? _c(
                    "a",
                    {
                      staticClass: "referral-card-button w-button",
                      attrs: { href: "#" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.vacancy.bonus) +
                          _vm._s(
                            _vm.vacancy.language_code === "bg" ? " лв." : "$"
                          )
                      ),
                    ]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "job-link w-inline-block",
                      attrs: { href: "#" },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/webflow-main/images/arrow.svg",
                          loading: "lazy",
                          width: "24",
                          height: "24",
                          alt: "",
                        },
                      }),
                    ]
                  ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }