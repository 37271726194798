var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "label",
        {
          staticClass: "form-label required",
          attrs: {
            id: "w-node-bac95f52-7d85-e9e1-9d85-c1f4d47e1868-d47e184b",
            for: "",
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translate("CV", _vm.translates)) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c("validation-provider", {
        staticClass: "position-relative-vue",
        attrs: { name: "fileName", rules: { required: true } },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _c("div", { staticClass: "input-wrapper" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fileName,
                        expression: "fileName",
                      },
                    ],
                    attrs: { name: "fileName", type: "text" },
                    domProps: { value: _vm.fileName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.fileName = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "input__file",
                    attrs: {
                      id: "input__file",
                      name: "myFile",
                      type: "file",
                      accept: "application/msword, application/pdf",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.uploadResume($event)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.fileOriginalName,
                          expression: "!fileOriginalName",
                        },
                      ],
                      staticClass: "input__file-button",
                      class: { "has-error": errors[0] || _vm.fileErrorText },
                      staticStyle: { "font-weight": "normal" },
                      attrs: { for: "input__file" },
                    },
                    [
                      _c("div", { staticClass: "file-upload-button" }, [
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: "/webflow-main/images/file.svg",
                              loading: "lazy",
                              alt: "",
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "attach-file-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.translate("Attach your CV", _vm.translates)
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.fileOriginalName
                    ? _c(
                        "div",
                        {
                          staticClass: "file-upload-button",
                          class: {
                            "has-error": errors[0] || _vm.fileErrorText,
                          },
                        },
                        [
                          _c("div", { staticClass: "attach-file-text" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.fileOriginalName.length > 25
                                    ? _vm.fileOriginalName.slice(0, 5) +
                                        "..." +
                                        _vm.fileOriginalName.slice(-20)
                                    : _vm.fileOriginalName
                                ) +
                                "\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("close-icon", {
                            staticClass: "close-icon-upload",
                            attrs: { size: 18 },
                            on: {
                              click: function ($event) {
                                return _vm.deleteFile(_vm.fileName)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.fileErrorText || errors[0]
                    ? _c("span", { staticClass: "validation-error-text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.fileErrorText
                                ? _vm.fileErrorText
                                : _vm.translate(errors[0], _vm.translates)
                            ) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }