import axios from 'axios';

/**
 * HiringRepository
 */
class HiringRepository {
    sendForm(application, csrf) {
        let formData = new FormData();

        formData.append('token', csrf);
        formData.append('username', application.username);
        formData.append('country', application.country);
        formData.append('email', application.email);
        formData.append('phone', application.phone);
        formData.append('money', application.salary);
        formData.append('messenger', application.messenger);
        formData.append('comment', application.comment);
        formData.append('filename', application.filename);
        formData.append('vacancy_id', application.vacancy_id);

        return axios.post('/api/application/create/',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }

    sendFormWithoutVacancy(application, csrf, language) {
        let formData = new FormData();
        formData.append('token', csrf);
        formData.append('language', language);
        formData.append('username', application.username);
        formData.append('email', application.email);
        formData.append('phone', application.phone);
        formData.append('filename', application.filename);
        formData.append('level', application.level);
        formData.append('direction', application.direction);

        return axios.post('/api/inquiry/',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }

    sendFile(file, csrf) {
        let formData = new FormData();
        formData.append('myFile', file);
        formData.append('token', csrf);

        return axios.post('/api/application/upload-file/',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }

    deleteFile(fileName, csrf) {
        let formData = new FormData();
        formData.append('filename', fileName);
        formData.append('token', csrf);

        return axios.post('/api/application/remove-file/',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }
}

export default new HiringRepository();
