import axios from 'axios';

/**
 * LocationsRepository
 */
class LocationsRepository {
    getList() {
        return axios.get('/api/admin/locations/');
    }

    getOne(id) {
        return axios.get('/api/admin/locations/' + id + '/');
    }

    create(location) {
        return axios.post('/api/admin/locations/create/', {
            'slug': location.slug,
            'is_geo': location.is_geo,
            'country': location.country,
            'city': location.city,
            'other': location.other
        });
    }

    edit(location) {
        return axios.put('/api/admin/locations/edit/' + location.id + '/', {
            'slug': location.slug,
            'is_geo': location.is_geo,
            'country': location.country,
            'city': location.city,
            'other': location.other
        });
    }

    remove(id) {
        return axios.delete('/api/admin/locations/delete/' + id + '/');
    }

    getCountriesList() {
        return axios.get('/api/admin/locations/get-countries/');
    }

    getCitiesList(countryCode) {
        return axios.get('/api/admin/locations/get-cities/?countryCode=' + countryCode);
    }

    getHiringCountriesList(token) {
        const config = {
            headers:{
                'token': token,
            }
        };

        return axios.get('/api/locations/get-countries/', config);
    }

    getHiringCitiesList(countryCode, token) {
        const config = {
            headers:{
                'token': token,
            }
        };

        return axios.get('/api/locations/get-cities/?countryCode=' + countryCode, config);
    }
    getLocationsByFilter(filters){
        return axios.get('/api/admin/locations/filter/', {
            params: filters
        });
    }

    getFilters() {
        return axios.get('/api/admin/filters/locations/');
    }
}

export default new LocationsRepository();
