<template>
    <div>
        <label
            id="w-node-bac95f52-7d85-e9e1-9d85-c1f4d47e1868-d47e184b"
            for=""
            class="form-label required"
        >
            {{ translate('CV', translates) }}
        </label>
        <validation-provider
            v-slot="{ errors }"
            name="fileName"
            :rules="{ required: true }"
            class="position-relative-vue"
        >
            <div class="input-wrapper">
                <input
                    v-show="false"
                    v-model="fileName"
                    name="fileName"
                    type="text"
                >
  
                <input
                    id="input__file"
                    class="input__file"
                    name="myFile"
                    type="file"
                    accept="application/msword, application/pdf"
                    @change="uploadResume($event)"
                >
  
                <label
                    v-show="!fileOriginalName"
                    for="input__file"
                    class="input__file-button"
                    style="font-weight: normal"
                    :class="{'has-error': errors[0] || fileErrorText}"
                >
                    <div class="file-upload-button">
                        <div>
                            <img
                                src="/webflow-main/images/file.svg"
                                loading="lazy"
                                alt=""
                            >
                        </div>
                        <div class="attach-file-text">{{ translate('Attach your CV', translates) }}</div>
                    </div>
                </label>
  
                <div
                    v-if="fileOriginalName"
                    class="file-upload-button"
                    :class="{'has-error': errors[0] || fileErrorText}"
                >
                    <div class="attach-file-text">
                        {{ fileOriginalName.length > 25 ? fileOriginalName.slice(0, 5) + '...' + fileOriginalName.slice(-20) : fileOriginalName }}
                    </div>
                    <close-icon
                        class="close-icon-upload"
                        :size="18"
                        @click="deleteFile(fileName)"
                    />
                </div>
                <span
                    v-if="fileErrorText || errors[0]"
                    class="validation-error-text"
                >
                    {{ fileErrorText ? fileErrorText : translate(errors[0], translates) }}
                </span>
            </div>
        </validation-provider>
    </div>
</template>

<script>
import HiringRepository from '../../../repositories/hiring';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import { translate } from '../helpers/translate';

extend('required', {
    ...required,
    message: 'This is a required field',
});

export default {
    components: {
        ValidationProvider,
        CloseIcon
    },
    props: {
        texts: {
            type: String,
            required: true
        },
        csrf: {
            type: String,
            required: true
        }
    },
    emits: ['update-file-name'],
    data: function() {
        return {
            fileErrorText: false,
            fileName: '',
            fileOriginalName: '',
            InputTypes: {},
            fileErrorType: {
                'maxSize': 'Wow, great experience.(max file size - 10MB)',
                'wrongFileFormat': 'Something is wrong here. (Available formats doc, docx, pdf, txt, odt, rtf)',
                '413': 'Wow, great experience.(max file size - 10MB)'
            },
            translates: []
        };
    },
    mounted(){
        this.translates = JSON.parse(this.texts);
    },
    methods: {
        uploadResume(event) {
            let file = event.target.files[0];
            if (!file) {
                return;
            }
            if (this.fileName) {
                this.deleteFile(this.fileName, true);
            }

            HiringRepository.sendFile(file, this.csrf).then((response) => {
                this.fileErrorText = false;
                this.fileName = response.data.file;
                this.$emit('update-file-name', this.fileName);
                this.fileOriginalName = response.data.file_original;
            }).catch((reason) => {
                let typeErrorKey;

                if (typeof (reason.response.data.message) !== 'undefined') {
                    typeErrorKey = reason.response.data.message.myFile;
                } else {
                    typeErrorKey = reason.response.status;
                }

                this.fileName = '';
                this.fileOriginalName = file.name;
                this.fileErrorText = this.translate(this.fileErrorType[typeErrorKey], this.translates);
                this.$emit('updateFileName', this.fileName);
            });
        },
        deleteFile(fileName, quiet) {
            if (!fileName) {
                this.fileOriginalName = '';
                this.fileErrorText = '';
                return;
            }

            HiringRepository.deleteFile(fileName, this.csrf).then(() => {
                if (this.fileName === fileName) {
                    this.fileName = false;
                    this.fileOriginalName = '';
                }
            }).catch(() => {
                if (this.fileName === fileName) {
                    this.fileName = false;
                    this.fileOriginalName = '';
                }

                if (quiet) {
                    return;
                }

                this.fileErrorText = 'Server error';
            });
        },
        translate
    }
};
</script>

<style scoped lang="scss">
.validation-error-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
    font-size: 16px;
    line-height: 24px;
    vertical-align: top;
    background: none;
    border-radius: 4px;
    color: rgba(234, 89, 107, 1);
    font-weight: 500;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.13);
}

.upload-file-text {
  text-align: center;
  color: #00b8e3;
  margin: 5px 0;
  display: flex;
  justify-content: center;
}

.upload-file-text span {
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close-icon {
    display: flex;
    align-items: center
}

.input__file {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.input__file-button-text {
  line-height: 1;
  margin-top: 1px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input__file-button {
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
}

.form-label.required::after {
    content: ' *';
    color: #7A9FFF;
}

.input__file-button:active {
  width: 100%;
}
.form-label {
    margin-bottom: 8px;
    padding-top: 12px;
}

.file-upload-button {
  width: 100%;
  max-width: 315px;
}

@media scren and (max-width: 767px) {
  .file-upload-button {
    width: 100%;
    max-width: 315px;
  }
}
</style>