import Vue from "vue";
import Form from "./js/components/pages/hiring/Form";
import VacancyList from "./js/components/pages/vacancy/VacancyList";
import FormWithoutVacancy from "./js/components/pages/home/FormWithoutVacancy"
import VueRouter from "vue-router";
import FormVacancy from "./js/components/pages/hiring/FormVacancy.vue";
import AllVacancies from "./js/components/pages/vacancies/AllVacancies";
import VacancyListItem from "./js/components/pages/vacancies/components/VacancyListItem.vue";

Vue.use(VueRouter);
Vue.component('hiring-form-component', Form);
Vue.component('vacancy-list-component', VacancyList);
Vue.component('all-vacancies', AllVacancies);
Vue.component('form-without-vacancy', FormWithoutVacancy);
Vue.component('form-vacancy', FormVacancy);
Vue.component('vacancy-list-item', VacancyListItem);

const router = new VueRouter({
    base: '/',
    mode: 'history',
});

const apps = document.querySelectorAll('.app');
for (let i = 0; i < apps.length; i++) {
    const app = apps[i];
    new Vue({
        el: app,
        router
    });
    
}

