var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "validation-observer",
    { ref: "observer", staticClass: "w-full in-block" },
    [
      _c(
        "form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.sendSuccess,
              expression: "!sendSuccess",
            },
          ],
          staticClass: "form",
          attrs: {
            id: "form-vacancy",
            name: "form-vacancy",
            "data-name": "Form vacancy",
            method: "post",
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-fields" },
            [
              _c("CustomInput", {
                staticClass: "field-container",
                attrs: {
                  "input-id": "username",
                  label: "Full name",
                  type: _vm.InputTypes.name,
                  texts: _vm.texts,
                  "is-required": true,
                  placeholder: "Full name",
                },
                model: {
                  value: _vm.vacancyForm.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.vacancyForm, "username", $$v)
                  },
                  expression: "vacancyForm.username",
                },
              }),
              _vm._v(" "),
              _vm.locale !== "bg"
                ? _c(
                    "div",
                    { staticClass: "field-container" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "form-label",
                          attrs: { for: "field-7" },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.translate("Country", _vm.translates))
                          ),
                          _c("span", { staticClass: "text-span-21" }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("CustomDropdown", {
                        attrs: {
                          "dropdown-id": "locationsDropdown",
                          texts: _vm.texts,
                          options: _vm.countries,
                        },
                        model: {
                          value: _vm.vacancyForm.country,
                          callback: function ($$v) {
                            _vm.$set(_vm.vacancyForm, "country", $$v)
                          },
                          expression: "vacancyForm.country",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("CustomInput", {
                staticClass: "field-container",
                attrs: {
                  "input-id": "email",
                  label: "Email",
                  type: _vm.InputTypes.email,
                  texts: _vm.texts,
                  "is-required": true,
                  placeholder: "hello@example.com",
                },
                model: {
                  value: _vm.vacancyForm.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.vacancyForm, "email", $$v)
                  },
                  expression: "vacancyForm.email",
                },
              }),
              _vm._v(" "),
              _c("CustomInput", {
                ref: "phoneField",
                staticClass: "field-container",
                attrs: {
                  "input-id": "phone",
                  label: "Phone number",
                  type: _vm.InputTypes.phone,
                  texts: _vm.texts,
                  "is-required": true,
                },
                model: {
                  value: _vm.vacancyForm.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.vacancyForm, "phone", $$v)
                  },
                  expression: "vacancyForm.phone",
                },
              }),
              _vm._v(" "),
              _c("CustomInput", {
                staticClass: "field-container",
                attrs: {
                  "input-id": "salary",
                  label: "Salary expectations per month",
                  type: _vm.InputTypes.salary,
                  texts: _vm.texts,
                  "is-required": true,
                },
                model: {
                  value: _vm.vacancyForm.salary,
                  callback: function ($$v) {
                    _vm.$set(_vm.vacancyForm, "salary", $$v)
                  },
                  expression: "vacancyForm.salary",
                },
              }),
              _vm._v(" "),
              _c("CustomInput", {
                staticClass: "field-container",
                attrs: {
                  "input-id": "messenger",
                  label: _vm.translate("Telegram", _vm.translates),
                  type: _vm.InputTypes.text,
                  texts: _vm.texts,
                  "is-required": false,
                },
                model: {
                  value: _vm.vacancyForm.messenger,
                  callback: function ($$v) {
                    _vm.$set(_vm.vacancyForm, "messenger", $$v)
                  },
                  expression: "vacancyForm.messenger",
                },
              }),
              _vm._v(" "),
              _c("CustomInput", {
                staticClass: "field-container",
                attrs: {
                  "input-id": "comment",
                  label: _vm.translate("Leave your comment", _vm.translates),
                  type: _vm.InputTypes.textarea,
                  texts: _vm.texts,
                  "label-classes": "align-self-start",
                },
                model: {
                  value: _vm.vacancyForm.comment,
                  callback: function ($$v) {
                    _vm.$set(_vm.vacancyForm, "comment", $$v)
                  },
                  expression: "vacancyForm.comment",
                },
              }),
              _vm._v(" "),
              _c("UploadCV", {
                staticClass: "field-container",
                attrs: { texts: _vm.texts, csrf: _vm.csrf },
                on: { "update-file-name": _vm.updateFileName },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.errorMessage
            ? _c("div", { staticClass: "form-error-message" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.errorMessage) + "\n        "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid-17" },
            [
              _c("validation-provider", {
                staticClass: "position-relative-vue checkbox-field-3",
                attrs: { name: "checkbox", rules: { isEqual: true } },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c(
                          "label",
                          {
                            staticClass:
                              "w-checkbox checkbox-agree custom-checkbox",
                          },
                          [
                            _c("div", {
                              staticClass:
                                "w-checkbox-input w-checkbox-input--inputType-custom form-checkbox",
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.agreement,
                                  expression: "agreement",
                                },
                              ],
                              staticStyle: {
                                opacity: "0",
                                position: "absolute",
                                "z-index": "-1",
                              },
                              attrs: {
                                id: "confirmation-2",
                                type: "checkbox",
                                name: "confirmation-2",
                                "data-name": "Confirmation 2",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.agreement)
                                  ? _vm._i(_vm.agreement, null) > -1
                                  : _vm.agreement,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.agreement,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.agreement = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.agreement = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.agreement = $$c
                                  }
                                },
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "form-checkbox-label required w-form-label",
                                attrs: { for: "confirmation-2" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.translate(
                                      "I agree to the processing of personal data",
                                      _vm.translates
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        errors[0]
                          ? _c(
                              "span",
                              { staticClass: "validation-error-text" },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.translate(
                                        "This is a required field",
                                        _vm.translates
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "form-submit-button w-button",
                  attrs: { type: "submit", "data-wait": "Please wait..." },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.sendForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.translate("Submit a request", _vm.translates)
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }