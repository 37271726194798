var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vacancies-container" }, [
    _vm.vacancies.length
      ? _c(
          "div",
          { staticClass: "w-layout-grid vacancies-holder" },
          _vm._l(_vm.vacancies, function (vacancy, index) {
            return _c("VacancyListItem", {
              key: vacancy.id,
              attrs: {
                index: index,
                "vacancy-prop": vacancy,
                "bonus-btn-prop": _vm.bonusBtnProp,
                locale: _vm.locale,
                "data-layer-event": "VacancyListClick",
              },
            })
          }),
          1
        )
      : _c("p", { staticClass: "nothing-found-text" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.translate("Sorry, there’s nothing found", _vm.translates)
              ) +
              "\n    "
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }