<template>
    <div class="vacancies-container">
        <div
            v-if="vacancies.length"
            class="w-layout-grid vacancies-holder"
        >
            <VacancyListItem
                v-for="(vacancy, index) in vacancies"
                :key="vacancy.id"
                :index="index"
                :vacancy-prop="vacancy"
                :bonus-btn-prop="bonusBtnProp"
                :locale="locale"
                data-layer-event="VacancyListClick"
            />
        </div>
        <p
            v-else
            class="nothing-found-text"
        >
            {{ translate('Sorry, there’s nothing found', translates) }}
        </p>
    </div>
</template>

<script>
import VacancyListItem from './VacancyListItem.vue';
import { translate } from '../../helpers/translate';

export default {
    components: {
        VacancyListItem
    },
    props: {
        locale: {
            type: String,
            default: 'en'
        },
        vacancies: {
            type: Array,
            required: true
        },
        texts: {
            type: String,
            required: true
        },
        bonusBtnProp: {
            type: Boolean,
            default: false
        }
    },
    data: function (){
        return {
            translates: []
        };
    },
    mounted() {
        this.translates = JSON.parse(this.texts);
    },
    methods: {
        translate
    }

};
</script>

<style scoped>
.vacancies-container {
    width: 100%;
}
.nothing-found-text {
    color: #fff;
    text-align: center;
    margin: 30px 50px 40px;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}
</style>