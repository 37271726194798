<template>
    <div>
        <div class="vacancies-filters">
            <div
                class="filter-dropdown w-dropdown"
                :class="{'dropdown-selected': selectedDirections.length}"
            >
                <div
                    class="dropdown-toggle w-dropdown-toggle"
                    @click="addFilterBg($event)"
                >
                    <div class="dropdown-text">
                        {{ translate('Direction', translates) }}
                    </div>
                    <div
                        v-if="selectedDirections.length"
                        class="selection-counter"
                    >
                        {{ selectedDirections.length }}
                    </div>
                    <img
                        src="/img/dropdown-arrow.svg"
                        loading="lazy"
                        alt=""
                        class="dropdown-arrow"
                    >
                </div>
                <nav
                    ref="directions"
                    class="dropdown-list w-dropdown-list"
                >
                    <div class="dropdown-wrapper">
                        <label
                            v-for="direction in directions"
                            :key="direction.id"
                            class="w-checkbox check-field"
                        >
                            <div
                                class="w-checkbox-input w-checkbox-input--inputType-custom filter-checkbox-check"
                                :class="{'w--redirected-checked': direction.checked}"
                            />
                            <input
                                id="Checkbox"
                                type="checkbox"
                                name="Checkbox"
                                data-name="Checkbox"
                                class="checkbox-input"
                                @click.stop="setFilter(direction, 'directions')"
                            ><span
                                class="filter-checkbox-label w-form-label"
                                for="Checkbox"
                            >
                                {{ direction.name }}
                            </span>
                        </label>
                        <div class="filter-done">
                            <a
                                href="#"
                                class="filter-done-button w-inline-block"
                            >
                                <div
                                    class="text-block-62"
                                    @click="filterItems({
                                        filterName: 'directions',
                                        filterSelectedName: 'selectedDirections'
                                    })"
                                >
                                    {{ translate('Done', translates) }}
                                </div>
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
            <div
                data-hover="false"
                data-delay="0"
                class="filter-dropdown w-dropdown"
                :class="{'dropdown-selected': selectedLocations.length}"
            >
                <div
                    class="dropdown-toggle w-dropdown-toggle"
                    @click="addFilterBg($event)"
                >
                    <div class="dropdown-text">
                        {{ translate('Location', translates) }}
                    </div>
                    <div
                        v-if="selectedLocations.length"
                        class="selection-counter"
                    >
                        {{ selectedLocations.length }}
                    </div>
                    <img
                        src="/img/dropdown-arrow.svg"
                        loading="lazy"
                        alt=""
                        class="dropdown-arrow"
                    >
                </div>
                <nav
                    ref="locations"
                    class="dropdown-list w-dropdown-list"
                >
                    <div class="dropdown-wrapper">
                        <label 
                            v-for="location in locations"
                            :key="location.id"
                            class="w-checkbox check-field"
                        >
                            <div
                                class="w-checkbox-input w-checkbox-input--inputType-custom filter-checkbox-check"
                                :class="{'w--redirected-checked': location.checked}"
                            />
                            <input
                                id="Checkbox"
                                type="checkbox"
                                name="Checkbox"
                                data-name="Checkbox"
                                class="checkbox-input"
                                @click="setFilter(location, 'locations')"
                            ><span
                                class="filter-checkbox-label w-form-label"
                                for="Checkbox"
                            >{{ location.name }}</span>
                        </label>

                        <div class="filter-done">
                            <a
                                href="#"
                                class="filter-done-button w-inline-block"
                            >
                                <div
                                    class="text-block-62"
                                    @click="filterItems({
                                        filterName: 'locations',
                                        filterSelectedName: 'selectedLocations'
                                    })"
                                >
                                    {{ translate('Done', translates) }}
                                </div>
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
            <div
                data-hover="false"
                data-delay="0"
                class="filter-dropdown w-dropdown"
                :class="{'dropdown-selected': selectedCompanies.length}"
            >
                <div
                    class="dropdown-toggle w-dropdown-toggle"
                    @click="addFilterBg($event)"
                >
                    <div class="dropdown-text">
                        {{ translate('Company', translates) }}
                    </div>
                    <div
                        v-if="selectedCompanies.length"
                        class="selection-counter"
                    >
                        {{ selectedCompanies.length }}
                    </div>
                    <img
                        src="/img/dropdown-arrow.svg"
                        loading="lazy"
                        alt=""
                        class="dropdown-arrow"
                    >
                </div>
                <nav
                    ref="companies"
                    class="dropdown-list w-dropdown-list"
                >
                    <div class="dropdown-wrapper">
                        <label 
                            v-for="company in companies"
                            :key="company.id"
                            class="w-checkbox check-field"
                        >
                            <div
                                class="w-checkbox-input w-checkbox-input--inputType-custom filter-checkbox-check"
                                :class="{'w--redirected-checked': company.checked}"
                            />
                            <input
                                id="Checkbox"
                                type="checkbox"
                                name="Checkbox"
                                data-name="Checkbox"
                                class="checkbox-input"
                                @click="setFilter(company, 'companies')"
                            ><span
                                class="filter-checkbox-label w-form-label"
                                for="Checkbox"
                            >{{ company.name }}</span>
                        </label>
                        <div class="filter-done">
                            <a
                                href="#"
                                class="filter-done-button w-inline-block"
                            >
                                <div
                                    class="text-block-62"
                                    @click="filterItems({
                                        filterName: 'companies',
                                        filterSelectedName: 'selectedCompanies'
                                    })"
                                >
                                    {{ translate('Done', translates) }}
                                </div>
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
            <div
                data-hover="false"
                data-delay="0"
                class="filter-dropdown w-dropdown"
                :class="{'dropdown-selected': selectedLevels.length}"
            >
                <div
                    class="dropdown-toggle w-dropdown-toggle"
                    @click="addFilterBg($event)"
                >
                    <div class="dropdown-text">
                        {{ translate('Level', translates) }}
                    </div>
                    <div
                        v-if="selectedLevels.length"
                        class="selection-counter"
                    >
                        {{ selectedLevels.length }}
                    </div>
                    <img
                        src="/img/dropdown-arrow.svg"
                        loading="lazy"
                        alt=""
                        class="dropdown-arrow"
                    >
                </div>
                <nav
                    ref="levels"
                    class="dropdown-list w-dropdown-list"
                >
                    <div class="dropdown-wrapper">
                        <label 
                            v-for="level in levels"
                            :key="level.id"
                            class="w-checkbox check-field"
                        >
                            <div
                                class="w-checkbox-input w-checkbox-input--inputType-custom filter-checkbox-check"
                                :class="{'w--redirected-checked': level.checked}"
                            />
                            <input
                                id="Checkbox"
                                type="checkbox"
                                name="Checkbox"
                                data-name="Checkbox"
                                class="checkbox-input"
                                @click="setFilter(level, 'levels')"
                            ><span
                                class="filter-checkbox-label w-form-label"
                                for="Checkbox"
                            >{{ level.name }}</span>
                        </label>
                        <div class="filter-done">
                            <a
                                href="#"
                                class="filter-done-button w-inline-block"
                            >
                                <div
                                    class="text-block-62"
                                    @click="filterItems({
                                        filterName: 'levels',
                                        filterSelectedName: 'selectedLevels'
                                    })"
                                >
                                    {{ translate('Done', translates) }}
                                </div>
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
            <div
                v-if="locale !== 'bg' && locale !== 'ru'"
                class="filter-dropdown w-dropdown"
                :class="{'dropdown-selected': selectedLanguages.length}"
            >
                <div
                    class="dropdown-toggle w-dropdown-toggle"
                    @click="addFilterBg($event)"
                >
                    <div class="dropdown-text">
                        {{ translate('Language', translates) }}
                    </div>
                    <div
                        v-if="selectedLanguages.length"
                        class="selection-counter"
                    >
                        {{ selectedLanguages.length }}
                    </div>
                    <img
                        src="/img/dropdown-arrow.svg"
                        loading="lazy"
                        alt=""
                        class="dropdown-arrow"
                    >
                </div>
                <nav
                    ref="languages"
                    class="dropdown-list w-dropdown-list"
                >
                    <div class="dropdown-wrapper">
                        <label 
                            v-for="language in languages"
                            :key="language.id"
                            class="w-checkbox check-field"
                        >
                            <div
                                class="w-checkbox-input w-checkbox-input--inputType-custom filter-checkbox-check"
                                :class="{'w--redirected-checked': language.checked}"
                            />
                            <input
                                :id="language.slug"
                                type="checkbox"
                                name="Checkbox"
                                data-name="Checkbox"
                                class="checkbox-input"
                                @click="setFilter(language, 'languages')"
                            >
                            <span
                                class="filter-checkbox-label w-form-label"
                                for="Checkbox"
                            >
                                {{ language.name }}
                            </span>
                        </label>
                        <div class="filter-done">
                            <a
                                href="#"
                                class="filter-done-button w-inline-block"
                            >
                                <div
                                    class="text-block-62"
                                    @click="filterItems({
                                        filterName: 'languages',
                                        filterSelectedName: 'selectedLanguages'
                                    })"
                                >
                                    {{ translate('Done', translates) }}
                                </div>
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
            <button
                class="clear-all-btn"
                @click="clearFilters"
            >
                {{ translate('Clear all', translates) }}
            </button>
        </div>
    </div>
</template>


<script>
import { translate } from '../../helpers/translate';
export default {
    props: {
        directionsProp: {
            type: String,
            required: true
        },
        locationsProp: {
            type: String,
            required: true
        },
        companiesProp: {
            type: String,
            required: true
        },
        levelsProp: {
            type: String,
            required: true
        },
        languagesProp: {
            type: String,
            required: true
        },
        texts: {
            type: String,
            required: true
        },
        locale: {
            type: String,
            default: 'en'
        }
    },
    emits: ['submit-filters'],
    data: function (){
        return {
            directions: [],
            selectedDirections: [],
            locations: [],
            selectedLocations: [],
            companies: [],
            selectedCompanies: [],
            levels: [],
            selectedLevels: [],
            languages: [],
            selectedLanguages: [],
            filters: {
                'directions': {
                    title: 'Directions',
                    selectedName: 'selectedDirections'
                },
                'locations': {
                    title: 'Locations',
                    selectedName: 'selectedLocations'
                },
                'companies': {
                    title: 'Companies',
                    selectedName: 'selectedCompanies'
                },
                'levels': {
                    title: 'Levels',
                    selectedName: 'selectedLevels'
                },
                'languages': {
                    title: 'Languages',
                    selectedName: 'selectedLanguages'
                },
            },
            translates: []
        };
    },
    mounted() {
        this.directions = JSON.parse(this.directionsProp); 
        this.locations = JSON.parse(this.locationsProp); 
        this.companies = JSON.parse(this.companiesProp); 
        this.levels = JSON.parse(this.levelsProp); 
        this.languages = JSON.parse(this.languagesProp); 
        this.translates = JSON.parse(this.texts);

        this.languages = this.languages.map((item) => {
            item.slug = item.code;
            item.checked = false;
            return item;
        });
        this.locations = this.locations.map((item) => {
            item.name = item.location;
            item.checked = false;
            return item;
        });
        this.setFilters();
        this.watchClickOutside();
    },
    methods: {
        watchClickOutside(){
            document.addEventListener('click', (event) => {
                for (let [filterName, filterItem] of Object.entries(this.filters)) {
                    const filterRef = this.$refs[filterName];
                    const isDropdownListOpen = filterRef?.classList.contains('w--open');
                    const isClickOutsideDropdown = !filterRef?.contains(event.target);
                    if (isDropdownListOpen && isClickOutsideDropdown) {
                        this.filterItems({filterName, filterSelectedName: filterItem.selectedName});
                    }
                }
            });
        },
        addFilterBg(event){
            event.currentTarget.parentElement.classList.add('dropdown-clicked');
        },
        setFilters() {
            const filtersPayload = {};

            for (let [filterName, filterItem] of Object.entries(this.filters)) {
                const queryArray = this.$route.query[filterName]?.split(',');

                this[filterName].forEach((item, index) => {
                    if(queryArray && queryArray.includes(item.slug)){
                        item.checked = true;
                        this.$set(this[filterName], index, item);

                    }

                    return item;
                });
                this[filterItem.selectedName] = this[filterName].filter(item => item.checked);

                filtersPayload[filterName] = this[filterItem.selectedName];
            }

            this.$emit('submit-filters', filtersPayload);
        },
        setFilter(filter, filterName){
            this[filterName].forEach((item, index) => {
                if (item.slug === filter.slug) {
                    item.checked = !filter.checked;
                    this.$set(this[filterName], index, item);
                }
            });
            for (let [filterName, filterItem] of Object.entries(this.filters)) {
                this.filterItems({filterName, filterSelectedName: filterItem.selectedName, hideAfterFilter: false});
            }
        },
        filterItems({ filterName, filterSelectedName, isClearAll, hideAfterFilter = true }) {
            this[filterSelectedName] = this[filterName].filter(item => item.checked);

            if (this[filterSelectedName].length) {
                this.addUrlQueryItem(filterName, this[filterSelectedName].map((element) => element.slug).join(','));
            } else {
                this.deleteUrlQueryItems(filterName);
            }

            const filtersPayload = {};

            for(let [filterName, filterItem] of Object.entries(this.filters)) {
                filtersPayload[filterName] = this[filterItem.selectedName];
            }

            filtersPayload.dataLayer = {
                'event': 'VacancyFilterInteraction',
                'vacancyFilterCategory': this.filters[filterName].title,
                'vacancyFilter': this[filterSelectedName].map(item=>item.name).join(', ')
            };

            filtersPayload.isClearAll = isClearAll;

            if (hideAfterFilter) {
                this.$refs[filterName]?.classList.remove('w--open');
                this.$refs[filterName]?.parentElement.classList.remove('dropdown-clicked');
            }
            this.$emit('submit-filters', filtersPayload);
        },
        addUrlQueryItem(nameString, valueString) {
            const url = new URL(window.location);
            url.searchParams.set(nameString, valueString);
            window.history.pushState({}, '', url);
        },
        deleteUrlQueryItems(nameString) {
            const url = new URL(window.location);
            url.searchParams.delete(nameString);
            window.history.pushState({}, '', url);
        },
        clearFilters() {
            for (let [filterName, filterItem] of Object.entries(this.filters)) {
                this[filterName].forEach((item, index) => {
                    item.checked = false;

                    this.$set(this[filterName], index, item);
                });
                this.filterItems({filterName, filterSelectedName: filterItem.selectedName, isClearAll: true});
            }
            this.$emit('clear-search');
        },
        translate
    }
};
</script>

<style lang="scss" scoped>
.w-checkbox-input {
    min-width: 18px;
}
.checkbox-input {
    opacity:0;
    position:absolute;
    z-index:-1;
}


.check-field {
    width: 100%;
    padding: 11.5px 0 11.5px 4.5px;
    margin: 0;
    cursor: pointer;
    background-color: transparent;
    &:hover {
        background-color: rgba(0, 4, 16, 0.34);
    }
}

.dropdown-list {
    padding-left: 0;
    width: 250px;
}

.clear-all-btn {
    margin: 0;
    display: block;
    background: transparent;
    color: #7a9fff;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    text-decoration: none;
}

@media screen and (max-width: 479px){
    .vacancies-filters {
        flex-direction: column;
        place-content: center;
    }
}
</style>