import axios from 'axios';

/**
 * VacancyRepository
 */
class VacancyRepository {
    getList() {
        return axios.get('/api/admin/vacancies/');
    }

    getListPublished() {
        return axios.get('/api/admin/vacancies/published/');
    }

    getListDraft() {
        return axios.get('/api/admin/vacancies/draft/');
    }

    getOne(id) {
        return axios.get('/api/admin/vacancies/' + id + '/');
    }

    create(vacancy) {
        return axios.post('/api/admin/vacancies/create/', {
            'name': vacancy.name,
            'is_published': vacancy.is_published,
            'is_hot': vacancy.is_hot,
            'language_id': vacancy.language_id,
            'skill_id': vacancy.skill_id,
            'company_id': vacancy.company_id,
            'recruiter_id': vacancy.recruiter_id,
            'level_id': vacancy.level_id,
            'locations': vacancy.locations,
            'short_description': vacancy.short_description,
            'desired_skills': vacancy.desired_skills,
            'we_expects': vacancy.we_expects,
            'we_offer': vacancy.we_offer,
            'bonus': vacancy.bonus,
        });
    }

    edit(vacancy) {
        return axios.put('/api/admin/vacancies/edit/' + vacancy.id + '/', {
            'name': vacancy.name,
            'is_published': vacancy.is_published,
            'is_hot': vacancy.is_hot,
            'language_id': vacancy.language_id,
            'skill_id': vacancy.skill_id,
            'company_id': vacancy.company_id,
            'recruiter_id': vacancy.recruiter_id,
            'level_id': vacancy.level_id,
            'locations': vacancy.locations,
            'short_description': vacancy.short_description,
            'desired_skills': vacancy.desired_skills,
            'we_expects': vacancy.we_expects,
            'we_offer': vacancy.we_offer,
            'bonus': vacancy.bonus,
        });
    }

    remove(id) {
        return axios.delete('/api/admin/vacancies/delete/' + id + '/');
    }

    copy(id) {
        return axios.post('/api/admin/vacancies/copy/' + id + '/');
    }

    search(searchObject, token) {
        const config = {
            headers:{
                'token': token,
            }
        };
        return axios.get('/api/vacancies/search/?' + new URLSearchParams(searchObject).toString(), config);
    }

    searchClues(searchObject,token) {
        const config = {
            headers:{
                'token': token,
            }
        };
        return axios.get('/api/vacancies/search/clues/?' + new URLSearchParams(searchObject).toString(), config);
    }


    getFilters() {
        return axios.get('/api/admin/filters/vacancies/');
    }

    getVacanciesByFilter(filters){
        return axios.get('/api/admin/vacancies/filter/', {
            params: filters
        });
    }
}

export default new VacancyRepository();