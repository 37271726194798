var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.label
        ? _c(
            "label",
            {
              staticClass: "form-label",
              class: [_vm.labelClasses, { required: _vm.isRequired }],
              attrs: { for: _vm.inputId },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.translate(_vm.label, _vm.translates)) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("validation-provider", {
        staticClass: "input-wrapper width-full",
        attrs: { name: _vm.type, rules: { ..._vm.rules } },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _vm.type !== _vm.InputTypes.textarea &&
                _vm.type !== _vm.InputTypes.phone
                  ? _c("input", {
                      staticClass: "form-field w-input",
                      attrs: {
                        id: _vm.inputId,
                        type: "text",
                        placeholder: _vm.translate(
                          _vm.placeholder,
                          _vm.translates
                        ),
                        autocomplete: "off",
                      },
                      domProps: { value: _vm.value },
                      on: {
                        input: function ($event) {
                          return _vm.handleTextInput($event)
                        },
                      },
                    })
                  : _vm.type === _vm.InputTypes.phone
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.phone,
                          expression: "phone",
                        },
                      ],
                      staticClass: "form-field w-input",
                      attrs: {
                        id: _vm.inputId,
                        placeholder: "+__(___)___-__-__",
                        type: "tel",
                        maxlength: "22",
                        minlength: "7",
                        autocomplete: "do-not-autofill",
                      },
                      domProps: { value: _vm.phone },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.phone = $event.target.value
                          },
                          _vm.changePhone,
                        ],
                        click: _vm.clickPhone,
                        paste: _vm.pastePhone,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "delete",
                              [8, 46],
                              $event.key,
                              ["Backspace", "Delete", "Del"]
                            )
                          )
                            return null
                          return _vm.deletePhoneKey.apply(null, arguments)
                        },
                      },
                    })
                  : _c("textarea", {
                      staticClass: "form-field form-field-textarea",
                      attrs: {
                        id: _vm.inputId,
                        placeholder: _vm.translate(
                          _vm.placeholder,
                          _vm.translates
                        ),
                        autocomplete: "off",
                        maxlength: "1000",
                      },
                      domProps: { value: _vm.value },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("input", $event.target.value)
                        },
                      },
                    }),
                _vm._v(" "),
                errors[0]
                  ? _c("span", { staticClass: "validation-error-text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.translate(errors[0], _vm.translates)) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }