<template>
    <div>
        <div
            v-for="(vacancy, index) in vacancies"
            :key="vacancy.id"
            class="container-13-cop2y w-container"
        >
            <a
                class="link-block-7 w-inline-block"
                :href="`/${vacancy.language_code}/vacancy/${vacancy.slug}/`"
                @click="sendEventToDataLayer(vacancy, index, $event)"
            >
                <div class="card-content">
                    <div class="tabs-2">
                        <div
                            v-if="showLanguageBlock"
                            class="language-chip"
                        >
                            <img
                                src="/img/language.svg"
                                loading="lazy"
                                width="24"
                                height="24"
                                alt=""
                                class="image-21"
                            >
                            <p class="text-in-chip-language">{{ vacancy.language_name }}</p>
                        </div>
                        <div class="without-icon">
                            <p class="text-in-chip">{{ vacancy.company_name }}</p>
                        </div>
                        <div class="without-icon">
                            <p class="text-in-chip">{{ vacancy.skill_name }}</p>
                        </div>
                    </div>
                    <div class="title-in-card">
                        <div
                            v-show="vacancy.is_hot"
                            class="fire"
                        >
                            <img
                                src="/img/fire.svg"
                                loading="lazy"
                                width="24"
                                height="24"
                                alt=""
                                class="image-19"
                            ></div>
                        <div class="card-title-2"> {{ vacancy.name }} <br></div>
                    </div>
                    <div class="location-in-card-2">
                        <div class="location-icon">
                            <img
                                src="/webflow-main/images/map_1map.png"
                                loading="lazy"
                                width="24"
                                height="24"
                                alt=""
                                class="image-17"
                            ></div>
                        <div class="text-block-39"> {{ vacancy.location_string }} </div>
                    </div>
                </div>
                <div
                    v-if="bonusBtn"
                    class="button-holder"
                >
                    <div class="money-button">{{ getBonus(vacancy) }} {{ getBonusInfo(vacancy) }}</div>
                </div>
                <div
                    v-if="!bonusBtn"
                    class="button-div"
                >
                    <div class="text-like-button-2">{{ translate('Show more') }}</div>
                </div>
            </a>
        </div>

        <p
            v-if="vacanciesNotFound"
            class="paragraph-4"
        >
            {{ translate('Sorry, there’s nothing found') }}.
        </p>
    </div>
</template>

<script>

export default {
    name: 'VacancyListItem',
    props: {
        'vacancies': {
            'type': Array,
            'default': () => {return [];}
        },
        'texts': {
            'type': String,
            'default': ''
        },
        'vacanciesNotFound': {
            'type': Boolean,
            'default': false
        },
        'showLanguageBlock': {
            'type': Boolean,
            'default': false
        },
        'bonusBtn': {
            'type': Boolean,
            'default': 0
        },
        'locale': {
            'type': String,
            'default': 0
        },
    },
    data: () => ({
        'translates': {}
    }),
    mounted() {
        this.translates = JSON.parse(this.texts);
    },
    methods: {
        translate(id) {
            if (this.translates[id]) {
                return this.translates[id];
            }

            return id;
        },
        getBonus(vacancy) {
            if (vacancy.bonus && vacancy.bonus > 0) {
                return vacancy.bonus;
            }

            let langKey = vacancy.language_code === 'bg' ? 'bonus_bgn' : 'bonus_usd';
            if (Object.prototype.hasOwnProperty.call(vacancy.level, langKey) && vacancy.level[langKey] > 0) {
                return vacancy.level[langKey];
            }

            return 0;
        },
        getBonusInfo(vacancy) {
            return vacancy.language_code === 'bg' ? 'лв.' : '$';
        },
        sendEventToDataLayer(vacancy, index, event) {
            event.preventDefault();
            let dataLayerElement = {
                'event': 'VacancyListClick',
                'vacancy': vacancy.name,
                'position': index + 1,
                'language': this.locale,
            };

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(dataLayerElement);
            window.location.href = `/${vacancy.language_code}/vacancy/${vacancy.slug}/`;
        }
    }
};
</script>

<style lang="scss" scoped>
    .hot-tab {
        background-color: transparent;
    }
    .card-title {
        text-transform: none;
    }
</style>