var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.vacancies, function (vacancy, index) {
        return _c(
          "div",
          { key: vacancy.id, staticClass: "container-13-cop2y w-container" },
          [
            _c(
              "a",
              {
                staticClass: "link-block-7 w-inline-block",
                attrs: {
                  href: `/${vacancy.language_code}/vacancy/${vacancy.slug}/`,
                },
                on: {
                  click: function ($event) {
                    return _vm.sendEventToDataLayer(vacancy, index, $event)
                  },
                },
              },
              [
                _c("div", { staticClass: "card-content" }, [
                  _c("div", { staticClass: "tabs-2" }, [
                    _vm.showLanguageBlock
                      ? _c("div", { staticClass: "language-chip" }, [
                          _c("img", {
                            staticClass: "image-21",
                            attrs: {
                              src: "/img/language.svg",
                              loading: "lazy",
                              width: "24",
                              height: "24",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-in-chip-language" }, [
                            _vm._v(_vm._s(vacancy.language_name)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "without-icon" }, [
                      _c("p", { staticClass: "text-in-chip" }, [
                        _vm._v(_vm._s(vacancy.company_name)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "without-icon" }, [
                      _c("p", { staticClass: "text-in-chip" }, [
                        _vm._v(_vm._s(vacancy.skill_name)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "title-in-card" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: vacancy.is_hot,
                            expression: "vacancy.is_hot",
                          },
                        ],
                        staticClass: "fire",
                      },
                      [
                        _c("img", {
                          staticClass: "image-19",
                          attrs: {
                            src: "/img/fire.svg",
                            loading: "lazy",
                            width: "24",
                            height: "24",
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-title-2" }, [
                      _vm._v(" " + _vm._s(vacancy.name) + " "),
                      _c("br"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "location-in-card-2" }, [
                    _vm._m(0, true),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-block-39" }, [
                      _vm._v(" " + _vm._s(vacancy.location_string) + " "),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm.bonusBtn
                  ? _c("div", { staticClass: "button-holder" }, [
                      _c("div", { staticClass: "money-button" }, [
                        _vm._v(
                          _vm._s(_vm.getBonus(vacancy)) +
                            " " +
                            _vm._s(_vm.getBonusInfo(vacancy))
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.bonusBtn
                  ? _c("div", { staticClass: "button-div" }, [
                      _c("div", { staticClass: "text-like-button-2" }, [
                        _vm._v(_vm._s(_vm.translate("Show more"))),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        )
      }),
      _vm._v(" "),
      _vm.vacanciesNotFound
        ? _c("p", { staticClass: "paragraph-4" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.translate("Sorry, there’s nothing found")) +
                ".\n    "
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "location-icon" }, [
      _c("img", {
        staticClass: "image-17",
        attrs: {
          src: "/webflow-main/images/map_1map.png",
          loading: "lazy",
          width: "24",
          height: "24",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }