<template>
    <validation-observer
        ref="observer"
    >
        <form
            v-show="!sendSuccess"
            id="wf-form--2"
            name="wf-form--2"
            data-name="Країна, де я зараз"
            method="get"
            class="form-6"
            data-wf-page-id="6478cab686097afaff09664e"
            data-wf-element-id="1303c0f1-07c9-eb9e-90cf-3ee7aa07ca1a"
            aria-label="Країна, де я зараз"
        >
            <div class="div-block-115">
                <label
                    for="field-4"
                    class="field-label"
                >
                    {{ translate('Name') }}<span class="text-span-21">*</span>
                </label>
                <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    class="input-wrapper width-full"
                    :rules="{ required: true, maxName: 50, regexName: nameRegex }"
                >
                    <input
                        id="field-4"
                        v-model.trim="name"
                        :class="{'has-error': errors[0]}"
                        name="name"
                        type="text"
                        class="text-field-7 w-input"
                        maxlength="256"
                    >
                    <span
                        v-if="errors[0]"
                        class="validation-error-text"
                    >
                        {{ translate(errors[0]) }}
                    </span>
                    <img
                        v-if="errors[0]"
                        class="error-button"
                        src="/img/warning-icon.svg"
                    >
                </validation-provider>
            </div>

            <div class="div-block-115">
                <label
                    for="field-5"
                    class="field-label"
                >
                    {{ translate('Last name') }}<span class="text-span-21">*</span>
                </label>
                <validation-provider
                    v-slot="{ errors }"
                    name="surname"
                    class="input-wrapper width-full"
                    :rules="{ required: true, maxLastname: 50, regexLastname: nameRegex }"
                >
                    <input
                        id="field-5"
                        v-model.trim="lastname"
                        :class="{'has-error': errors[0]}"
                        type="text"
                        class="text-field-7 w-input"
                        maxlength="256"
                        data-name="Прізвище"
                        placeholder=""
                        name="surname"
                    >
                    <span
                        v-if="errors[0]"
                        class="validation-error-text"
                    >
                        {{ translate(errors[0]) }}
                    </span>
                    <img
                        v-if="errors[0]"
                        class="error-button"
                        src="/img/warning-icon.svg"
                    >
                </validation-provider>
            </div>

            <div class="div-block-115">
                <label
                    for="field-7"
                    class="field-label"
                >
                    {{ translate('Location') }}<span class="text-span-21" />
                </label>
                <div class="input-wrapper width-full">
                    <select
                        id="field-7"
                        v-model="country"
                        name="country"
                        data-name="Країна, де я зараз"
                        class="select-field w-select"
                        @change="loadCities(country, true)"
                    >
                        <option value="">
                            {{ translate('Location') }}
                        </option>
                        <option
                            v-for="chooseCountry in countries"
                            :key="chooseCountry.name"
                            :value="chooseCountry.name"
                        >
                            {{ chooseCountry.name }}
                        </option>
                        <option value="other">
                            Other
                        </option>
                    </select>
                    <span
                        v-show="country"
                        class="select-delete"
                    >
                        <close-icon
                            :size="18"
                            :fill-color="'#ffffff'"
                            @click="clearCountry()"
                        />
                    </span>
                </div>
            </div>

            <div class="div-block-115">
                <label
                    for="field-9"
                    class="field-label"
                >
                    {{ translate('City') }} <span class="text-span-21" />
                </label>
                <div class="input-wrapper width-full">
                    <select
                        id="field-9"
                        v-model="city"
                        name="field-9"
                        data-name="Field 9"
                        class="select-field w-select"
                    >
                        <option
                            disabled
                            selected
                            value=""
                        >
                            {{ translate('City') }}
                        </option>
                        <option
                            v-for="countryCity in cities"
                            :key="countryCity.name"
                            :value="countryCity.name"
                        >
                            {{ countryCity.name }}
                        </option>
                        <option value="other">
                            Other
                        </option>
                    </select>
                    <span
                        v-show="city"
                        class="select-delete"
                    >
                        <close-icon
                            :size="18"
                            :fill-color="'#ffffff'"
                            @click="city = ''"
                        />
                    </span>
                </div>
            </div>

            <validation-provider
                v-slot="{ errors }"
                name="email"
                :rules="{ required: true, maxEmail: 50, email }"
                class="div-block-115"
            >
                <label
                    for="Name-6"
                    class="field-label"
                >
                    {{ translate('Email') }}<span class="text-span-21">*</span>
                </label>
                <div class="input-wrapper width-full">
                    <input
                        id="Name-6"
                        v-model.trim="email"
                        :class="{'has-error': errors[0]}"
                        type="email"
                        class="text-field-7 w-input"
                        maxlength="256"
                        name="email"
                        data-name="Name 5"
                        placeholder=""
                    >
                    <span
                        v-if="errors[0]"
                        class="validation-error-text"
                    >
                        {{ translate(errors[0]) }}
                    </span>
                    <img
                        v-if="errors[0]"
                        class="error-button"
                        src="/img/warning-icon.svg"
                    >
                </div>
            </validation-provider>

            <validation-provider
                v-slot="{ errors }"
                name="number"
                :rules="{ required: true, numericMonthlySalary: true, maxMonthlySalary: 10 }"
                class="div-block-115"
            >
                <label
                    for="name-5"
                    class="field-label"
                >
                    {{ translate('Salary expectations per month') }}<span class="text-span-21">*</span>
                </label>

                <div class="input-wrapper width-full">
                    <input
                        id="name-5"
                        v-model.trim="salary"
                        :class="{'has-error': errors[0]}"
                        type="text"
                        class="text-field-7 w-input"
                        maxlength="256"
                        name="number"
                        data-name="Name 5"
                        placeholder=""
                    >
                    <span
                        v-if="errors[0]"
                        class="validation-error-text"
                    >
                        {{ translate(errors[0]) }}
                    </span>
                    <img
                        v-if="errors[0]"
                        class="error-button"
                        src="/img/warning-icon.svg"
                    >
                </div>
            </validation-provider>

            <validation-provider
                v-slot="{ errors }"
                name="phone"
                :rules="{ required: true, maskIncorrectPhone: true}"
                class="div-block-115"
            >
                <label
                    for="phone"
                    class="field-label"
                >
                    {{ translate('Phone number') }}<span class="text-span-21">*</span>
                </label>
                <div class="input-wrapper width-full">
                    <input
                        id="phone"
                        v-model="phone"
                        :class="{'has-error': errors[0]}"
                        type="tel"
                        class="text-field-7 w-input"
                        data-name="phone"
                        maxlength="22"
                        minlength="7"
                        name="phone"
                        placeholder=""
                        autocomplete="off"
                        @input="changePhone"
                        @click="clickPhone"
                        @paste="pastePhone"
                        @keyup.delete="deletePhoneKey"
                    >
                    <span
                        v-if="errors[0]"
                        class="validation-error-text"
                    >
                        {{ translate(errors[0]) }}
                    </span>
                    <img
                        v-if="errors[0]"
                        class="error-button"
                        src="/img/warning-icon.svg"
                    >
                </div>
            </validation-provider>

            <div class="div-block-115">
                <label
                    for="messanger"
                    class="field-label"
                >
                    {{ translate('Messenger') }}<span
                        class="text-span-21"
                    />
                </label>
                <validation-provider
                    v-slot="{ errors }"
                    name="messanger"
                    :rules="{ maxSocialNetworkAcc: 500 }"
                    class="input-wrapper width-full"
                >
                    <input
                        id="messanger"
                        v-model.trim="messenger"
                        :class="{'has-error': errors[0]}"
                        type="text"
                        class="text-field-7 w-input"
                        maxlength="256"
                        name="messanger"
                        data-name="Messanger"
                        placeholder=""
                    >
                    <span
                        v-if="errors[0]"
                        class="validation-error-text"
                    >
                        {{ translate(errors[0]) }}
                    </span>
                    <img
                        v-if="errors[0]"
                        class="error-button"
                        src="/img/warning-icon.svg"
                    >
                </validation-provider>
            </div>

            <div class="div-block-115">
                <label
                    for="ld"
                    class="field-label"
                >
                    {{ translate('LinkedIn') }}
                </label>
                <validation-provider
                    v-slot="{ errors }"
                    name="ld"
                    class="input-wrapper width-full"
                    :rules="{ maxLinkedInAcc: 500 }"
                >
                    <input
                        id="ld"
                        v-model.trim="linkedin"
                        :class="{'has-error': errors[0]}"
                        type="text"
                        class="text-field-7 w-input"
                        maxlength="256"
                        name="ld"
                        data-name="Linkedin"
                    >
                    <span
                        v-if="errors[0]"
                        class="validation-error-text"
                    >
                        {{ translate(errors[0]) }}
                    </span>
                    <img
                        v-if="errors[0]"
                        class="error-button"
                        src="/img/warning-icon.svg"
                    >
                </validation-provider>
            </div>

            <div class="div-block-115">
                <label
                    for="field"
                    class="field-label"
                >
                    {{ translate('Leave your comment') }}
                </label>
                <validation-provider
                    v-slot="{ errors }"
                    name="comment"
                    :rules="{ maxComment: 1000 }"
                    class="input-wrapper width-full"
                >
                    <textarea
                        id="field"
                        v-model.trim="comment"
                        name="comment"
                        maxlength="5000"
                        data-name="Field 3"
                        class="textarea w-input comment-textarea"
                        :class="{'has-error': errors[0]}"
                    />
                    <span
                        v-if="errors[0]"
                        class="validation-error-text"
                    >
                        {{ translate(errors[0]) }}
                    </span>
                    <img
                        v-if="errors[0]"
                        class="error-button"
                        src="/img/warning-icon.svg"
                    >
                </validation-provider>
            </div>


            <div class="div-block-115">
                <label
                    for="name-5"
                    class="field-label"
                >
                    {{ translate('Link to your portfolio') }}
                </label>
                <validation-provider
                    v-slot="{ errors }"
                    name="protfolio"
                    :rules="{ maxPortfolioLink: 500 }"
                    class="input-wrapper width-full"
                >
                    <input
                        id="name-5"
                        v-model.trim="portfolioLink"
                        type="text"
                        class="text-field-7 w-input"
                        maxlength="256"
                        name="name-5"
                        data-name="Name 5"
                        placeholder=""
                        :class="{'has-error': errors[0]}"
                    >
                    <span
                        v-if="errors[0]"
                        class="validation-error-text"
                    >
                        {{ translate(errors[0]) }}
                    </span>
                    <img
                        v-if="errors[0]"
                        class="error-button"
                        src="/img/warning-icon.svg"
                    >
                </validation-provider>
            </div>

            <div class="div-block-115">
                <label
                    for="name-5"
                    class="field-label"
                >
                    {{ translate('CV') }}<span class="text-span-21">*</span>
                </label>
                <validation-provider
                    v-slot="{ errors }"
                    name="fileName"
                    :rules="{ required_if: portfolioLink === '' }"
                    class="position-relative-vue"
                >
                    <div class="input-wrapper">
                        <input
                            v-show="false"
                            v-model="fileName"
                            name="fileName"
                            type="text"
                        >

                        <input
                            id="input__file"
                            class="input__file"
                            name="myFile"
                            type="file"
                            @change="uploadResume($event)"
                        >

                        <label
                            v-show="!fileOriginalName"
                            for="input__file"
                            class="input__file-button"
                            style="font-weight: normal"
                            :class="{'has-error': errors[0] || fileErrorText}"
                        >
                            <div class="div-block-125">
                                <div>
                                    <img
                                        src="/webflow-main/images/File-upload.svg"
                                        loading="lazy"
                                        alt=""
                                    >
                                </div>
                                <div class="text-block-55">{{ translate('Attach your CV') }}</div>
                            </div>
                        </label>

                        <div
                            v-if="fileOriginalName"
                            class="div-block-125 file-uploaded"
                            :class="{'has-error': errors[0] || fileErrorText}"
                        >
                            <div class="text-block-55">
                                {{ fileOriginalName.length > 25 ? fileOriginalName.slice(0, 5) + '...' + fileOriginalName.slice(-20) : fileOriginalName }}
                            </div>
                            <close-icon
                                class="close-icon-upload"
                                :size="18"
                                @click="deleteFile(fileName)"
                            />
                        </div>
                        <span
                            v-if="fileErrorText || errors[0]"
                            class="validation-error-text"
                        >
                            {{ fileErrorText ? fileErrorText : translate(errors[0]) }}
                        </span>
                    </div>
                    <img
                        v-if="fileErrorText || errors[0]"
                        class="error-button file-uploader-error"
                        src="/img/warning-icon.svg"
                    >
                </validation-provider>
            </div>

            <div class="divider" />

            <validation-provider
                v-slot="{ errors }"
                name="checkbox"
                :rules="{ isEqual: true }"
                class="position-relative-vue input-wrapper"
            >
                <label class="w-checkbox checkbox-field-2 checkbox-privacy">
                    <div
                        :class="{'w--redirected-checked': agreement, 'has-error': errors[0]}"
                        class="w-checkbox-ageement w-checkbox-input--inputType-custom checkbox-2"
                        style="cursor: pointer"
                    />
                    <input
                        id="checkbox-3"
                        v-model="agreement"
                        type="checkbox"
                        name="checkbox-3"
                        data-name="Checkbox 3"
                        style="opacity:0;position:absolute;z-index:-1"
                    >
                    <span
                        v-if="errors[0]"
                        class="validation-error-text"
                    >
                        {{ translate('This is a required field') }}
                    </span>
                    <span
                        class="checkbox-label-2 w-form-label checkbox-content-box"
                        for="checkbox-3"
                    >
                        <a
                            :href="privacy"
                            target="_blank"
                            class="link-25"
                        >
                            {{ translate('I agree to the processing of personal data') }}
                        </a>
                        <span class="text-span-21">*</span>
                    </span>
                </label>
                <img
                    v-if="errors[0]"
                    class="error-button checkbox-item"
                    src="/img/warning-icon.svg"
                >
            </validation-provider>

            <div class="div-block-121">
                <input
                    v-show="!blocked"
                    :value="translate('Submit a request')"
                    type="submit"
                    data-wait="Waiting..."
                    class="submit-button-3 w-button"
                    @click="sendForm"
                >
            </div>
        </form>
    </validation-observer>
</template>

<script>

import HiringRepository from '../../../repositories/hiring';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import {between, email, is, max, numeric, regex, required, required_if} from 'vee-validate/dist/rules';
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from 'vee-validate';
import LocationRepository from '../../../repositories/settings/locations';
import Inputmask from 'inputmask';
import PhoneCodes from '../../../../phone-codes.json';
import PageHelper from '../../../helpers/page-helper';

setInteractionMode('eager');

extend('url', {
    validate(value) {
        let pattern = new RegExp('^(https?:\\/\\/)?' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$', 'i');
        return pattern.test(value);
    },
    message: 'Incorrect format'
});

extend('isEqual', {
    getMessage: field => 'Field ' + field + ' is required.',
    validate: value => value
});

extend('maxName', {
    ...max,
    message: 'It\'s a great name! But it seems to have too many characters (max. 50 characters).',
});

extend('regexName', {
    ...regex,
    message: 'I think there is something extra here. Allowed characters letters, hyphen, apostrophe, space.',
});

extend('maxLastname', {
    ...max,
    message: 'So many letters! Let\'s shorten it (max. 50 characters).',
});

extend('regexLastname', {
    ...regex,
    message: 'This will not work. Allowed characters letters, hyphen, apostrophe, space.',
});

extend('maxEmail', {
    ...max,
    message: 'Oops, your email is too long (max 50 characters).',
});

extend('email', {
    ...email,
    message: 'Oops, typo! Allowed characters latin letters, numbers, @, dot, hyphen, underscore.',
});

extend('maxMonthlySalary', {
    ...max,
    message: 'We appreciate your ambition, but let\'s try a smaller number (max. 50 characters).',
});

extend('numericMonthlySalary', {
    ...numeric,
    message: 'This field is for numbers only. Please check the spelling.',
});

extend('maxSocialNetworkAcc', {
    ...max,
    message: 'You\'re out of line. (max. 500 characters).',
});

extend('maxLinkedInAcc', {
    ...max,
    message: 'Let\'s shorten the link (max. 500 characters).',
});

extend('maxComment', {
    ...max,
    message: 'Wow, that\'s a big comment. Let\'s try to make it shorter, shall we? (max 1000 characters).',
});

extend('maxPortfolioLink', {
    ...max,
    message: 'Cool portfolio, but let\'s shorten the link. (max 500 characters).',
});

extend('is', {
    ...is,
    message: 'Incorrect format',
});

extend('between', {
    ...between,
    message: 'Incorrect format',
});

extend('required_if', {
    ...required_if,
    message: 'This is a required field',
});

extend('required', {
    ...required,
    message: 'This is a required field',
});

extend('maskIncorrectPhone', {
    params: [],
    validate() {
        let phone_selector = document.getElementById('phone');
        return phone_selector.inputmask ? phone_selector.inputmask.isComplete() : false;
    },
    message: 'Where should we call? Check the correct number format.'
});

export default {
    name: 'FormComponent',
    components: {
        CloseIcon,
        ValidationProvider,
        ValidationObserver
    },
    props: {
        'texts': {
            'type': String,
            'default': ''
        },
        'csrf': {
            'type': String,
            'default': ''
        },
        'vacancy': {
            'type': String,
            'default': ''
        },
        'vacancyName': {
            'type': String,
            'default': ''
        },
        'language': {
            'type': String,
            'default': ''
        },
    },
    data: () => ({
        privacy: '',
        nameRegex: '^[A-Za-zА-Яа-яІЄҐDŽіїєґéèêčřdžäöüāáǎà]([ A-Za-zА-Яа-яІЇЄҐŽDіїєґéèêčřdžäöüāáǎà`\'-]*)[A-Za-zА-Яа-яІЄҐDāŽa-zа-яїєґéèêčřdžäöüāáǎà]$',
        translates: {},
        fileErrorText: false,
        fileName: '',
        fileOriginalName: '',
        fileErrorType: {
            'maxSize': 'Wow, great experience.(max file size - 10MB)',
            'wrongFileFormat': 'Something is wrong here. (Available formats doc, docx, pdf, txt, odt, rtf)',
            '413': 'Wow, great experience.(max file size - 10MB)'
        },
        name: '',
        lastname: '',
        countries: [],
        cities: [],
        country: '',
        city: '',
        email: '',
        salary: null,
        phone: null,
        messenger: '',
        linkedin: '',
        comment: '',
        portfolioLink: '',
        agreement: false,
        blocked: false,
        sendSuccess: false,
        phoneMask: '',
        oldPhoneValue: '',
        defaultPhoneMask: '+999(99)999-99',
        phoneSelector: '',
    }),
    mounted() {
        const privacyList = {
            'en': 'https://career.netpeak.group/en/privacy/',
            'ua': 'https://career.netpeak.group/ua/privacy/',
            'bg': 'https://career.netpeak.group/en/privacy/',
            'ru': 'https://career.netpeak.group/en/privacy/'
        };
        this.phoneSelector = document.getElementById('phone');
        this.privacy = privacyList[window.location.pathname.split('/')[1]];
        this.translates = JSON.parse(this.texts);
        LocationRepository.getHiringCountriesList(this.csrf).then((response) => {
            this.countries = response.data;
        });
    },
    methods: {
        changePhone: function () {
            let cleanPhone = this.phone.replace(/\D/g, '');
            let defaultPhoneMask = this.defaultPhoneMask;
            if (this.phone.length > 0) {
                this.oldPhoneValue = cleanPhone;
            }
            let oldPhone = this.oldPhoneValue;
            let phoneSelector = this.phoneSelector;
            let showMask = '';
            let phoneWithoutMask;
            let matchResults;

            for (let i = 6; i > 0; i--) {
                matchResults = this.searchMatch(cleanPhone.slice(0, i), cleanPhone);
                if (matchResults.countMatch > 0 && matchResults.completeMatch) {
                    showMask = matchResults.completeMatch;
                    phoneWithoutMask = cleanPhone.replace(matchResults.clearMask, '');
                    break;
                }
            }

            if (showMask.length > 0) {
                this.phone = phoneWithoutMask;
                this.phoneMask = showMask;
                phoneSelector.value = phoneWithoutMask;

                Inputmask(showMask, {
                    definitions: {
                        '#': {
                            validator: '[0-9]',
                            cardinality: 1,
                            definitionSymbol: '*'
                        },
                    },
                    'oncleared': function () {
                        phoneSelector.inputmask.remove();
                        phoneSelector.value = '+' + oldPhone.slice(0, -1);
                        Inputmask({mask: defaultPhoneMask}).mask(phoneSelector);
                    }
                }
                ).mask(phoneSelector);
            }

            if (phoneSelector.inputmask && phoneSelector.inputmask.isComplete()) {
                phoneSelector.inputmask.remove();
                phoneSelector.value = this.phone;
                Inputmask({mask: showMask}).mask(phoneSelector);
                this.phone = phoneSelector.value;
            }
        },
        searchMatch: function (phone, cleanPhone) {
            let countMatch = 0;
            let completeMatch = null;
            let clearMask = null;

            PhoneCodes.forEach(function (item) {
                let mask = item.mask.replace(/\D/g, '');
                let maskLength = item.mask.replace(/#/gi, '9').replace(/\D/g, '').length;
                let phoneRegular = new RegExp('^' + phone);
                let match = phoneRegular.exec(mask);

                if (match) {
                    if (mask === phone && maskLength >= cleanPhone.length) {
                        countMatch++;
                        completeMatch = item.mask;
                        completeMatch = item.mask.replace(/9/gi, '\\9');
                        if (countMatch > 1 && maskLength === cleanPhone.length) {
                            completeMatch = completeMatch.concat('[#]');
                        }
                        clearMask = mask;
                    }
                }
            });
            return {'countMatch': countMatch, 'completeMatch': completeMatch, 'clearMask': clearMask};
        },
        clickPhone: function () {
            let phone_selector = document.getElementById('phone');
            if (!this.phoneSelector.inputmask) {
                Inputmask({mask: this.defaultPhoneMask}).mask(phone_selector);
            }
        },
        pastePhone: function () {
            if (this.phoneSelector.inputmask) {
                this.phoneSelector.inputmask.remove();
            }
        },
        deletePhoneKey: function (e) {
            if (e.target.value === '' && this.phoneSelector.inputmask) {
                this.phoneSelector.inputmask.remove();
                this.oldPhoneValue = this.oldPhoneValue.slice(0, -2);
                this.phoneSelector.value = '+' + this.oldPhoneValue;
                Inputmask({mask: this.defaultPhoneMask}).mask(this.phoneSelector);
            }
        },
        translate(id) {
            if (this.translates[id]) {
                return this.translates[id];
            }

            return id;
        },
        uploadResume(event) {
            let file = event.target.files[0];
            if (!file) {
                return;
            }
            if (this.fileName) {
                this.deleteFile(this.fileName, true);
            }

            HiringRepository.sendFile(file, this.csrf).then((response) => {
                this.fileErrorText = false;
                this.fileName = response.data.file;
                this.fileOriginalName = response.data.file_original;
            }).catch((reason) => {
                let typeErrorKey;

                if (typeof (reason.response.data.message) !== 'undefined') {
                    typeErrorKey = reason.response.data.message.myFile;
                } else {
                    typeErrorKey = reason.response.status;
                }

                this.fileName = '';
                this.fileOriginalName = file.name;
                this.fileErrorText = this.translate(this.fileErrorType[typeErrorKey]);
            });
        },
        deleteFile(fileName, quiet) {
            if (!fileName) {
                this.fileOriginalName = '';
                this.fileErrorText = '';
                return;
            }

            HiringRepository.deleteFile(fileName, this.csrf).then(() => {
                if (this.fileName === fileName) {
                    this.fileName = false;
                    this.fileOriginalName = '';
                }
            }).catch(() => {
                if (this.fileName === fileName) {
                    this.fileName = false;
                    this.fileOriginalName = '';
                }

                if (quiet) {
                    return;
                }

                this.fileErrorText = 'Server error';
            });
        },
        sendForm(e) {
            e.preventDefault();
            this.$refs.observer.validate().then((isValid) => {
                if (!isValid) {
                    let errorMessageString = '';
                    for(let errorItem in this.$refs.observer.errors) {
                        let errorMessages = this.$refs.observer.errors[errorItem];
                        if (!errorMessages.length) {
                            continue;
                        }
                        errorMessageString += errorItem + '-' + errorMessages.join(',') + ';';
                    }

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'Form_Sent_Error',
                        'vacancy': this.vacancyName,
                        'deviceID': PageHelper.getDeviceID(),
                        'language': this.language,
                        'errorText': errorMessageString,
                    });
                    return;
                }

                this.blocked = true;
                HiringRepository.sendForm({
                    username: this.name + ' ' + this.lastname,
                    country: this.country,
                    city: this.city,
                    email: this.email,
                    phone: this.phone,
                    money: this.salary,
                    messenger: this.messenger,
                    ld: this.linkedin,
                    comment: this.comment,
                    filename: this.fileName,
                    portfolio_link: this.portfolioLink,
                    vacancy_id: this.vacancy,
                }, this.csrf).then(() => {
                    this.sendSuccess = true;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'FormSentSuccess',
                        'vacancy': this.vacancyName,
                        'deviceID': PageHelper.getDeviceID(),
                        'language': this.language
                    });
                    document.getElementById('form-content').style.display = 'none';
                    const customSuccess = document.getElementById('custom-success-message');
                    customSuccess.style.display = 'block';
                    window.scrollTo({
                        top: customSuccess.offsetTop - 80,
                        behavior: 'smooth'
                    });
                    this.blocked = false;
                }).catch((reason) => {
                    this.blocked = false;
                    this.deleteFile(false, true);
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'Form_Sent_Error',
                        'vacancy': this.vacancyName,
                        'deviceID': PageHelper.getDeviceID(),
                        'language': this.language,
                        'errorText': reason.data.message,
                    });
                });
            });
        },
        loadCities(countryName, clearCity) {
            if (!countryName) {
                return;
            }
            if (clearCity && this.city) {
                this.city = '';
            }

            let country = this.countries.find(element => element.name === countryName);
            if (!country || !country.code) {
                this.cities = [];
                return;
            }

            LocationRepository.getHiringCitiesList(country.code, this.csrf).then((response) => {
                this.cities = response.data;
            });
        },
        clearCountry() {
            this.country = '';
            this.city = '';
            this.cities = [];
        }
    }
};
</script>

<style lang="scss" scoped>
.close-icon {
  cursor: pointer;
}

.text-error {
  text-align: center;
  margin: 5px 0;
  color: red;
}

.upload-file-text {
  text-align: center;
  color: #00b8e3;
  margin: 5px 0;
  display: flex;
  justify-content: center;
}

.upload-file-text span {
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input__file {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.input__file-button-text {
  line-height: 1;
  margin-top: 1px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input__file-button {
  width: 100%;
  margin: 0 auto;
}

.input__file-button:active {
  width: 100%;
}

.w-checkbox-ageement {
  float: left;
  line-height: normal;
}

.success-message {
  display: block;
  margin: 0 auto;
}

.success-message a {
  color: #ffffff;
}

.w-select {
  padding-right: 20px;
}

.input-wrapper {
  position: relative;
}

.width-full {
  width: 372px;
}

@media (max-width: 767px) {
  .width-full {
    width: 100%;
  }
}

.select-delete {
  position: absolute;
  bottom: 9px;
  right: 24px;
  cursor: pointer;
}

.validation-error-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  font-size: 14px;
  vertical-align: top;
  top: 55px;
  left: 20px;
  position: absolute;
  opacity: 0;
  background: #000000;
  border-radius: 4px;
  color: #F2B8B5;
  font-weight: normal;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.13);

  img {
    padding: 0 5px 2px 0;
    position: relative;
  }
}

.validation-error-text:before {
  content: "";
  position: absolute;
  left: 1.4rem;
  top: -0.4rem;
  width: 0.9rem;
  height: 0.9rem;
  transform: rotate(45deg);
  background: #000000;
}

.input-wrapper input:hover + .validation-error-text,
.input-wrapper .input__file-button:hover + .validation-error-text,
.input-wrapper .file-uploaded:hover + .validation-error-text {
  opacity: 1;
  z-index: 20;
}

.input-wrapper textarea:hover + .validation-error-text {
  opacity: 1;
  z-index: 20;
  top: auto;
  bottom: -62px;
}


@media screen and (max-width: 479px) {
  .mob-ml-30 {
    margin-left: 30px;
  }
}

.file-uploaded {
  color: #00b8e3;
  word-break: break-all;
  max-width: 370px;
  height: auto;
  min-height: 44px;
  padding: 3px;
}

.has-error {
  color: #F2B8B5;
  border: 1px solid #F2B8B5;
}

.has-error::placeholder {
  color: #F2B8B5;
}

.comment-textarea {
  color: #FFFFFF;
}

.position-relative-vue {
  position: relative;
}

.error-button {
  position: absolute;
  right: 7px;
  top: 10px;
}

.error-button.file-uploader-error {
  right: -40px;
}

.error-button.checkbox-item {
  right: 0;
  left: -30px;
  top: 0;
}

.w-input {
  padding-right: 40px;
}

.has-error .upload-file-text {
  color: #F2B8B5;
}

.w-checkbox {
  position: relative;
  z-index: 2;
}

.close-icon-upload {
  margin-top: 5px;
}

@media (max-width: 480px) {
  .input__file-button-text {
    max-width: 200px;
  }

  .error-button.file-uploader-error {
    right: 22px;
  }

  .error-button.checkbox-item {
    right: 7px;
    left: auto;
    top: 10px;
  }

  .checkbox-content-box {
    padding-right: 27px;
  }

  .input-wrapper .file-uploaded + .validation-error-text,
  .input-wrapper .w-checkbox input + .validation-error-text,
  .input-wrapper .input__file-button + .validation-error-text {
    opacity: 1;
    z-index: 20;
  }
}
</style>
