<template>
    <section class="vacancies-list-replace">
        <div class="section-container">
            <vacancy-search 
                :locale="locale"
                :csrf="csrf"
                :texts="texts"
                :search-value="query"
                @submitSearch="submitSearch"
                @update-search-value="handleUpdateSearchValue"
            />
            <vacancy-filters 
                :directions-prop="directionsProp"
                :locations-prop="locationsProp"
                :companies-prop="companiesProp"
                :levels-prop="levelsProp"
                :languages-prop="languagesProp"
                :texts="texts"
                :locale="locale"
                @submit-filters="onSubmitFilters"
                @clear-search="handleClearSearch"
            />
            <div class="vacancies-section">
                <div class="vacancies-counter-block">
                    <div class="vacancies-counter">
                        {{ translate('Vacancies', translates) }}:
                        <span class="vacancies-counter-number">{{ vacancies.length }}</span>
                    </div>
                </div>
                <vacancy-list
                    :locale="locale"
                    :vacancies="vacancies"
                    :texts="texts"
                    :bonus-btn-prop="bonusBtnProp"
                />
            </div>
        </div>
    </section>
</template>

<script>
import VacancyList from './components/VacancyList.vue';
import VacancyFilters from './components/VacancyFilters.vue';
import VacancySearch from './components/VacancySearch.vue';
import VacancyRepository from '../../../repositories/vacancies';
import { translate } from '../helpers/translate';


export default {
    components: {
        VacancySearch,
        VacancyFilters,
        VacancyList
    },
    props: {
        locale: {
            type: String,
            default: 'en'
        },
        csrf: {
            type: String,
            default: ''
        },
        texts: {
            type: String,
            default: ''
        },
        companiesProp: {
            type: String,
            default: ''
        },
        directionsProp: {
            type: String,
            default: ''
        },
        levelsProp: {
            type: String,
            default: ''
        },
        locationsProp: {
            type: String,
            default: ''
        },
        languagesProp: {
            type: String,
            default: ''
        },
        vacanciesProp: {
            type: String,
            default: ''
        },
        bonusBtnProp: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        query: '',
        blocked: false,
        vacancies: [],
        sourceVacancies: [],
        filters: {},
        translates: {},
    }),
    mounted() {
        window.onscroll = function() {
            scrollFunction();
        };

        function scrollFunction() {
            const goTopButton = document.querySelector('.go-top-button');
            if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
                goTopButton.style.display = 'block';
            } else {
                goTopButton.style.display = 'none';
            }
        }
        if (this.$route.query.query) {
            this.query = this.$route.query.query;
        }
        this.translates = JSON.parse(this.texts);
        this.sourceVacancies = JSON.parse(this.vacanciesProp);
        this.vacancies = JSON.parse(this.vacanciesProp);

        this.setUrlQueryState();
    },
    methods: {
        setUrlQueryState(){
            const url = new URL(window.location);
            let existQuery = url.searchParams.get('query');

            if (!this.query && existQuery) {
                url.searchParams.delete('query');
                window.history.pushState({}, '', url);
            }

            if (this.query) {
                url.searchParams.set('query', this.query);
                window.history.pushState({}, '', url);
            }
        },
        handleUpdateSearchValue(value){
            this.query = value;
        },
        searchVacancies(dataLayer) {
            if (this.query.length === 1) {
                return;
            }

            if (this.blocked) {
                return;
            }

            this.blocked = true;
            VacancyRepository.search({
                locale: this.locale,
                query: this.query,
                clues: 0
            },this.csrf).then((response) => {
                this.sourceVacancies = response.data;
                this.vacancies = response.data;

                this.setUrlQueryState();

                this.blocked = false;
                if (dataLayer) {
                    dataLayer.results = this.vacancies.length;
                    dataLayer.language = this.locale;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(dataLayer);
                }

                this.onSubmitFilters();
            }).catch(() => {
                this.blocked = false;
                this.vacancies = [];
            });
        },
        async onSubmitFilters(filtersPayload) {
            if (filtersPayload) {
                this.filters = filtersPayload;
            }

            const { directions, levels, languages, companies, locations, dataLayer, isClearAll } = this.filters;
            await this.$nextTick();
            
            this.vacancies = this.sourceVacancies.filter((item) => {
                if (directions.length && !directions.filter(((direction) => direction.id === item.skill_id)).length) {
                    return false;
                }

                if (levels.length && !levels.filter(((level) => level.id === item.level_id)).length) {
                    return false;
                }

                if (languages.length && !languages.filter(((language) => language.id === item.language_id)).length) {
                    return false;
                }

                if (companies.length && !companies.filter(((company) => company.id === item.company_id)).length) {
                    return false;
                }

                if (locations.length) {
                    for (let location of locations) {
                        for (let settedLocation of item.locations) {
                            return location.id === settedLocation.id;
                        }
                    }
                }

                return true;
            });

            if (dataLayer && !isClearAll) {
                dataLayer.results = this.vacancies.length;
                dataLayer.language = this.locale;

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(dataLayer);
            }

        },
        dataLayerPush() {
            let dataLayer = {
                'event': 'SearchInteraction',
                'searchQuery': this.query,
                'results': this.vacancies.length,
                'language': this.locale
            };

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(dataLayer);
        },
        submitSearch({query, dataLayer}) {
            this.query = query;
            this.searchVacancies(dataLayer);
        },
        handleClearSearch() {
            this.query = '';
            this.searchVacancies();
        },
        translate
    }
};
</script>

<style lang="scss" scoped>
.vacancies-list-replace {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.section-container {
    width: 100%;
    max-width: 970px;
}

@media screen and (max-width: 992px) {
    .vacancies-section {
        padding-top: 20px;
    }
}
</style>