<template>
    <section class="vacancies-list-replace">
        <vacancy-search-form
            :csrf="csrf"
            :texts="texts"
            :locale="locale"
            @submitSearch="submitSearch"
        />
        <div class="container-18 w-container">
            <div class="div-block-14 priority-top">
                <div
                    data-hover="false"
                    data-delay="0"
                    class="dropdown-5 w-dropdown"
                    style=""
                >
                    <div
                        id="w-dropdown-toggle-1"
                        class="dropdown-toggle-4 w-dropdown-toggle"
                        aria-controls="w-dropdown-list-1"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        role="button"
                        tabindex="0"
                    >
                        <div class="div-block-13">
                            <img
                                height="24"
                                width="24"
                                src="/img/arrow-drop-down.svg"
                                loading="lazy"
                                alt=""
                            >
                        </div>
                        <div class="text-block-26">
                            <span class="text-span-10">{{ translate('Direction') }}</span>
                        </div>
                    </div>

                    <nav
                        id="w-dropdown-list-1"
                        class="dropdown-list-16 w-dropdown-list"
                        aria-labelledby="w-dropdown-toggle-1"
                    >
                        <div
                            class="mobile-panel"
                            @click="closeDropdown('#w-dropdown-toggle-1')"
                            @touchstart="swipe"
                            @touchmove="swipeDetect"
                        >
                            <div class="tap-mobile-panel" />
                            {{ translate('Choose direction') }}
                        </div>
                        <div class="mobile-list">
                            <div
                                v-for="direction in directions"
                                :key="direction.id"
                                class="filter-item"
                                @click="direction.checked = !direction.checked; setFiltersState(direction, 'Direction');"
                            >
                                <div class="checkbox-filter check-box-outline-blank">
                                    <label class="container-checkbox">
                                        <input
                                            :checked="direction.checked"
                                            type="checkbox"
                                            @click="direction.checked = !direction.checked; setFiltersState(direction, 'Direction');"
                                        >
                                        <span class="checkmark" />
                                    </label>
                                </div>
                                <div class="text-block-27">
                                    {{ direction.name }}
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>

                <div
                    data-hover="false"
                    data-delay="0"
                    class="dropdown-13 w-dropdown"
                    style=""
                >
                    <div
                        id="w-dropdown-toggle-2"
                        class="dropdown-toggle-4 w-dropdown-toggle"
                        aria-controls="w-dropdown-list-2"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        role="button"
                        tabindex="0"
                    >
                        <div>
                            <img
                                height="24"
                                width="24"
                                src="/img/arrow-drop-down.svg"
                                loading="lazy"
                                alt=""
                            >
                        </div>
                        <div class="text-block-26">
                            <span class="text-span-10">{{ translate('Location') }}</span>
                        </div>
                    </div>
                    <nav
                        id="w-dropdown-list-2"
                        class="_2-list-filter w-dropdown-list"
                        aria-labelledby="w-dropdown-toggle-2"
                    >
                        <div
                            class="mobile-panel"
                            @click="closeDropdown('#w-dropdown-toggle-2')"
                            @touchstart="swipe"
                            @touchmove="swipeDetect"
                        >
                            <div class="tap-mobile-panel" />
                            {{ translate('Choose location') }}
                        </div>
                        <div class="mobile-list">
                            <div
                                v-for="location in locations"
                                :key="location.id"
                                class="filter-item"
                                @click="location.checked = !location.checked; setFiltersState(location, 'Location');"
                            >
                                <div class="checkbox-filter check-box-outline-blank">
                                    <label class="container-checkbox">
                                        <input
                                            :checked="location.checked"
                                            type="checkbox"
                                            @click="location.checked = !location.checked; setFiltersState(location, 'Location');"
                                        >
                                        <span class="checkmark" />
                                    </label>
                                </div>
                                <div class="text-block-27">
                                    {{ location.name }}
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>

                <div
                    data-hover="false"
                    data-delay="0"
                    class="dropdown-13 w-dropdown"
                    style=""
                >
                    <div
                        id="w-dropdown-toggle-3"
                        class="dropdown-toggle-4 w-dropdown-toggle"
                        aria-controls="w-dropdown-list-3"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        role="button"
                        tabindex="0"
                    >
                        <div>
                            <img
                                height="24"
                                width="24"
                                src="/img/arrow-drop-down.svg"
                                loading="lazy"
                                alt=""
                            >
                        </div>
                        <div class="text-block-26">
                            <span class="text-span-10">{{ translate('Company') }}</span>
                        </div>
                    </div>

                    <nav
                        id="w-dropdown-list-3"
                        class="dropdown-list-11 w-dropdown-list"
                        aria-labelledby="w-dropdown-toggle-3"
                    >
                        <div
                            class="mobile-panel"
                            @click="closeDropdown('#w-dropdown-toggle-3')"
                            @touchstart="swipe"
                            @touchmove="swipeDetect"
                        >
                            <div class="tap-mobile-panel" />
                            {{ translate('Choose company') }}
                        </div>
                        <div class="mobile-list">
                            <div
                                v-for="company in companies"
                                :key="company.id"
                                class="filter-item"
                                @click="company.checked = !company.checked; setFiltersState(company, 'Company')"
                            >
                                <div class="checkbox-filter check-box-outline-blank">
                                    <label class="container-checkbox">
                                        <input
                                            :checked="company.checked"
                                            type="checkbox"
                                            @click="company.checked = !company.checked; setFiltersState(company, 'Company')"
                                        >
                                        <span class="checkmark" />
                                    </label>
                                </div>
                                <div class="text-block-27">
                                    {{ company.name }}
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>

                <div
                    data-hover="false"
                    data-delay="0"
                    class="dropdown-13 w-dropdown"
                    style=""
                >
                    <div
                        id="w-dropdown-toggle-4"
                        class="dropdown-toggle-4 w-dropdown-toggle"
                        aria-controls="w-dropdown-list-4"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        role="button"
                        tabindex="0"
                    >
                        <div>
                            <img
                                height="24"
                                width="24"
                                src="/img/arrow-drop-down.svg"
                                loading="lazy"
                                alt=""
                            >
                        </div>
                        <div class="text-block-26">
                            <span class="text-span-10">{{ translate('Level') }}</span>
                        </div>
                    </div>

                    <nav
                        id="w-dropdown-list-4"
                        class="dropdown-list-19 w-dropdown-list"
                        aria-labelledby="w-dropdown-toggle-4"
                    >
                        <div
                            class="mobile-panel"
                            @click="closeDropdown('#w-dropdown-toggle-4')"
                            @touchstart="swipe"
                            @touchmove="swipeDetect"
                        >
                            <div class="tap-mobile-panel" />
                            {{ translate('Choose level') }}
                        </div>
                        <div class="mobile-list">
                            <div
                                v-for="level in levels"
                                :key="level.id"
                                class="filter-item"
                                @click="level.checked = !level.checked; setFiltersState(level, 'Level')"
                            >
                                <div class="checkbox-filter check-box-outline-blank">
                                    <label class="container-checkbox">
                                        <input
                                            :checked="level.checked"
                                            type="checkbox"
                                            @click="level.checked = !level.checked; setFiltersState(level, 'Level')"
                                        >
                                        <span class="checkmark" />
                                    </label>
                                </div>
                                <div class="text-block-27">
                                    {{ level.name }}
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>

                <div
                    v-if="showFilterLang.includes(locale)"
                    data-hover="false"
                    data-delay="0"
                    class="dropdown-13 w-dropdown"
                    style=""
                >
                    <div
                        id="w-dropdown-toggle-5"
                        class="dropdown-toggle-4 w-dropdown-toggle"
                        aria-controls="w-dropdown-list-5"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        role="button"
                        tabindex="0"
                    >
                        <div>
                            <img
                                height="24"
                                width="24"
                                src="/img/arrow-drop-down.svg"
                                loading="lazy"
                                alt=""
                            >
                        </div>
                        <div class="text-block-26">
                            <span class="text-span-10">{{ translate('Language') }}</span>
                        </div>
                    </div>

                    <nav
                        id="w-dropdown-list-5"
                        class="dropdown-list-19 w-dropdown-list"
                        aria-labelledby="w-dropdown-toggle-5"
                    >
                        <div
                            class="mobile-panel"
                            @click="closeDropdown('#w-dropdown-toggle-5')"
                            @touchstart="swipe"
                            @touchmove="swipeDetect"
                        >
                            <div class="tap-mobile-panel" />
                            {{ translate('Choose language') }}
                        </div>
                        <div class="mobile-list">
                            <div
                                v-for="language in languages"
                                :key="language.id"
                                class="filter-item"
                                @click="language.checked = !language.checked; setFiltersState(language, 'Language')"
                            >
                                <div class="checkbox-filter check-box-outline-blank">
                                    <label class="container-checkbox">
                                        <input
                                            :checked="language.checked"
                                            type="checkbox"
                                            @click="language.checked = !language.checked; setFiltersState(language, 'Language')"
                                        >
                                        <span class="checkmark" />
                                    </label>
                                </div>
                                <div class="text-block-27">
                                    {{ language.name }}
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>

        <div class="container-16 w-container">
            <h2 class="title">
                {{ translate('Vacancies') }}
            </h2>
            <div class="text-block-28">
                {{ vacancies.length }}
            </div>
        </div>

        <div class="container-17 w-container priority-0">
            <div class="div-block-28">
                <div
                    v-for="filter in filtersItems"
                    :key="filter.slug"
                    class="div-block-27"
                >
                    <div class="text-block-31">
                        {{ filter.name }}
                    </div>
                    <img
                        src="/webflow-main/images/Close.svg"
                        height="20"
                        width="20"
                        alt=""
                        class="close"
                        @click="removeFilter(filter)"
                    >
                </div>
                <a
                    v-show="filtersItems.length"
                    class="button-8 w-button"
                    @click="clearFilter"
                >
                    {{ translate('Clear all') }}
                </a>
            </div>
        </div>

        <vacancy-list-item
            :vacancies="vacancies"
            :texts="texts"
            :vacancies-not-found="vacanciesNotFound"
            :show-language-block="showFilterLang.includes(locale)"
            :bonus-btn="bonusBtnProp"
            :locale="locale"
        />
    </section>
</template>

<script>
import VacancyRepository from '../../../repositories/vacancies';
import VacancyListItem from './VacancyListItem.vue';
import VacancySearchForm from './elements/VacancySearchForm.vue';

export default {
    name: 'VacancyList',
    components: {VacancyListItem, VacancySearchForm},
    props: {
        'locale': {
            'type': String,
            'default': 'en'
        },
        'csrf': {
            'type': String,
            'default': ''
        },
        'texts': {
            'type': String,
            'default': ''
        },
        'companiesProp': {
            'type': String,
            'default': ''
        },
        'directionsProp': {
            'type': String,
            'default': ''
        },
        'levelsProp': {
            'type': String,
            'default': ''
        },
        'locationsProp': {
            'type': String,
            'default': ''
        },
        'languagesProp': {
            'type': String,
            'default': ''
        },
        'vacanciesProp': {
            'type': String,
            'default': ''
        },
        'bonusBtnProp': {
            'type': Boolean,
            'default': false
        },
    },
    data: () => ({
        swipeStart: null,
        showFilterLang: ['ua','en'],
        vacancies: [],
        sourceVacancies: [],
        filtersItems: [],
        companies: [],
        directions: [],
        levels: [],
        locations: [],
        languages: [],
        translates: {},
        query: '',
        blocked: false,
        vacanciesNotFound: false,
        firstLoad: true,
    }),
    watch: {
        vacancies(value) {
            this.vacanciesNotFound = !value.length;
        },
        filtersItems(value) {
            const callback = (item) => {
                item.checked = value.includes(item);
                return item;
            };
            this.directions = this.directions.map(callback);
            this.companies = this.companies.map(callback);
            this.levels = this.levels.map(callback);
            this.languages = this.languages.map(callback);
            this.locations = this.locations.map(callback);
        }
    },
    mounted() {
        if (this.$route.query.query) {
            this.query = this.$route.query.query;
        }
        this.translates = JSON.parse(this.texts);
        this.companies = JSON.parse(this.companiesProp);
        this.directions = JSON.parse(this.directionsProp);
        this.levels = JSON.parse(this.levelsProp);
        this.locations = JSON.parse(this.locationsProp);
        this.languages = JSON.parse(this.languagesProp);
        this.sourceVacancies = JSON.parse(this.vacanciesProp);
        this.vacancies = JSON.parse(this.vacanciesProp);

        this.getList();
    },
    methods: {
        getList(){
            let callback = (item) => {
                item.checked = false;
                return item;
            };
            this.companies = this.companies.map(callback);
            this.directions = this.directions.map(callback);
            this.levels = this.levels.map(callback);
            this.languages = this.languages.map((item) => {
                item.slug = item.code;
                item.checked = false;
                return item;
            });
            this.locations = this.locations.map((item) => {
                item.name = item.location;
                item.checked = false;
                return item;
            });

            this.setUrlQueryState();
            this.setFilters();
        },
        swipe(e) {
            this.swipeStart = e.touches[0];
        },
        swipeDetect(e) {
            if (this.swipeStart === null) {
                return;
            }
            if (this.swipeStart.clientY < e.touches[0].clientY) {
                let element = e.target.parentElement.previousElementSibling !== null ?
                    e.target.parentElement.previousElementSibling :
                    e.target.parentElement.parentElement.previousElementSibling;

                element.click();
            }
            this.swipeStart = null;
        },
        closeDropdown(idClick) {
            let querySelector = document.querySelector(idClick);
            querySelector.click();
        },
        clearFilter() {
            let callback = (item) => {
                item.checked = false;
                return item;
            };
            this.directions = this.directions.map(callback);
            this.companies = this.companies.map(callback);
            this.levels = this.levels.map(callback);
            this.languages = this.languages.map(callback);
            this.locations = this.locations.map(callback);

            for (let nameString of ['companies', 'directions', 'levels', 'languages', 'locations']) {
                this.deleteUrlQueryItems(nameString);
            }
            this.vacancies = this.sourceVacancies;
            this.filtersItems.length = 0;
        },
        setFiltersState(item, filter) {
            if (item.checked) {
                this.addFilter(item);
                let dataLayer = {
                    'event': 'VacancyFilterInteraction',
                    'vacancyFilterCategory': filter,
                    'vacancyFilter': item.name,
                    'results': this.vacancies.length,
                    'language': this.locale,
                };
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(dataLayer);
                return;
            }

            this.removeFilter(item);
        },
        addFilter(item) {
            let itemExists = this.filtersItems.filter((arrayItem) => item.slug === arrayItem.slug);
            if (itemExists.length) {
                return;
            }
            this.filtersItems.push(item);
            this.changeVacationsVisibility();
        },
        removeFilter(item) {
            this.filtersItems = this.filtersItems.filter((arrayItem) => item.slug !== arrayItem.slug);
            this.changeVacationsVisibility();
        },
        isBonusIsset(vacancy) {
            if (vacancy.bonus && vacancy.bonus > 0) {
                return true;
            }

            let langKey = vacancy.language_code === 'bg' ? 'bonus_bgn' : 'bonus_usd';

            return Object.prototype.hasOwnProperty.call(vacancy.level, langKey) && vacancy.level[langKey] > 0;
        },
        filterReferrers() {
            if (this.bonusBtnProp) {
                this.vacancies = this.vacancies.filter((item) => {
                    return this.isBonusIsset(item);
                });
            }
        },
        setFilters() {
            const itemsCallback = (items, currentArray) => {
                let itemsArray = items.split(',');

                return currentArray.map((item) => {
                    item.checked = itemsArray.includes(item.slug);
                    if (itemsArray.includes(item.slug)) {
                        this.addFilter(item);
                    }

                    return item;
                });
            };
            if (this.$route.query.directions) {
                this.directions = itemsCallback(this.$route.query.directions, this.directions);
            }

            if (this.$route.query.companies) {
                this.companies = itemsCallback(this.$route.query.companies, this.companies);
            }

            if (this.$route.query.levels) {
                this.levels = itemsCallback(this.$route.query.levels, this.levels);
            }

            if (this.$route.query.languages) {
                this.languages = itemsCallback(this.$route.query.languages, this.languages);
            }

            if (this.$route.query.locations) {
                this.locations = itemsCallback(this.$route.query.locations, this.locations);
            }

            this.filterReferrers();
        },
        changeVacationsVisibility() {
            const callback = (suspectedItem) => {
                for (let filterItem of this.filtersItems) {
                    if (filterItem.slug === suspectedItem.slug) {
                        return true;
                    }
                }
                return false;
            };

            let directionsActive = this.directions.filter(callback),
                locationsActive = this.locations.filter(callback),
                levelsActive = this.levels.filter(callback),
                companiesActive = this.companies.filter(callback),
                languagesActive = this.languages.filter(callback);

            if (directionsActive.length) {
                this.addUrlQueryItem('directions', directionsActive.map((element) => element.slug).join(','));
            } else {
                this.deleteUrlQueryItems('directions');
            }

            if (locationsActive.length) {
                this.addUrlQueryItem('locations', locationsActive.map((element) => element.slug).join(','));
            } else {
                this.deleteUrlQueryItems('locations');
            }

            if (levelsActive.length) {
                this.addUrlQueryItem('levels', levelsActive.map((element) => element.slug).join(','));
            } else {
                this.deleteUrlQueryItems('levels');
            }

            if (languagesActive.length) {
                this.addUrlQueryItem('languages', languagesActive.map((element) => element.slug).join(','));
            } else {
                this.deleteUrlQueryItems('languages');
            }

            if (companiesActive.length) {
                this.addUrlQueryItem('companies', companiesActive.map((element) => element.slug).join(','));
            } else {
                this.deleteUrlQueryItems('companies');
            }

            this.vacancies = this.sourceVacancies.filter((item) => {
                if (directionsActive.length && !directionsActive.filter(((direction) => direction.id === item.skill_id)).length) {
                    return false;
                }

                if (levelsActive.length && !levelsActive.filter(((level) => level.id === item.level_id)).length) {
                    return false;
                }

                if (languagesActive.length && !languagesActive.filter(((language) => language.id === item.language_id)).length) {
                    return false;
                }

                if (companiesActive.length && !companiesActive.filter(((company) => company.id === item.company_id)).length) {
                    return false;
                }

                if (this.bonusBtnProp && !this.isBonusIsset(item)) {
                    return false;
                }

                if (locationsActive.length) {
                    for (let location of locationsActive) {
                        for (let settedLocation of item.locations) {
                            if (location.id === settedLocation.id) {
                                return true;
                            }
                        }
                        return false;
                    }
                    return false;
                }

                return true;
            });
        },
        addUrlQueryItem(nameString, valueString) {
            const url = new URL(window.location);
            url.searchParams.set(nameString, valueString);
            window.history.pushState({}, '', url);
        },
        deleteUrlQueryItems(nameString) {
            let url = new URL(window.location);
            url.searchParams.delete(nameString);
            window.history.pushState({}, '', url);
        },
        setUrlQueryState(){
            const url = new URL(window.location);
            let existQuery = url.searchParams.get('query');

            if (!this.query && existQuery) {
                url.searchParams.delete('query');
                window.history.pushState({}, '', url);
            }

            if (this.query) {
                url.searchParams.set('query', this.query);
                window.history.pushState({}, '', url);
            }
        },
        searchVacancies() {
            if (this.query.length === 1) {
                return;
            }

            if (this.blocked) {
                return;
            }

            this.blocked = true;
            VacancyRepository.search({
                locale: this.locale,
                query: this.query,
                clues: 0
            },this.csrf).then((response) => {
                this.sourceVacancies = response.data;
                this.vacancies = response.data;

                this.setUrlQueryState();

                if (this.firstLoad) {
                    this.setFilters();
                    this.firstLoad = false;
                } else {
                    this.changeVacationsVisibility();
                }
                this.blocked = false;
                this.dataLayerPush();
            }).catch(() => {
                this.blocked = false;
                this.vacancies = [];
            });
        },
        dataLayerPush() {
            let dataLayer = {
                'event': 'SearchInteraction',
                'searchQuery': this.query,
                'results': this.vacancies.length,
                'language': this.locale
            };

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(dataLayer);
        },
        submitSearch(query) {
            this.query = query;
            this.searchVacancies();
        },
        translate(id) {
            if (this.translates[id]) {
                return this.translates[id];
            }

            return id;
        },
    }
};
</script>

<style lang="scss" scoped>
    .priority-top {
        z-index: 1;
    }

    .priority-0 {
        z-index: 0;
    }

    .div-block-27 img:hover {
        cursor: pointer;
    }

    .vacancies-list-replace {
        .search-button-4 {
            min-width: 207px;
        }
    }

    .filter-item:hover {
        background-color: #00b8e3;
        color: #ffffff;
        cursor: pointer;
    }

    .filter-item:hover .checkmark {
        border: 2px solid #ffffff;
    }

    /** checkboxes */
    .container-checkbox {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .checkbox-filter {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
    }

    .container-checkbox input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .container-checkbox .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      border: 2px solid #898C8E;
      border-radius: 2px;
    }

    .filter-item:hover .container-checkbox .checkmark {
      border: 2px solid #ffffff;
    }

    .container-checkbox:hover input ~ .checkmark {
      border: 2px solid #ffffff;
    }

    .container-checkbox .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    .container-checkbox input:checked ~ .checkmark:after {
      display: block;
    }

    .filter-item:hover .container-checkbox input:checked ~ .checkmark:after {
      border: solid white;
      border-width: 0 3px 3px 0;
    }

    .container-checkbox .checkmark:after {
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid #898C8E;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .vacancies-list-replace .mobile-panel {
      display: none;
    }

    @media screen and (min-width: 479px) {
        .w-dropdown {
            z-index: 5;
        }
    }

    @media screen and (max-width: 479px) {
      .vacancies-list-replace {
        .dropdown-toggle-4 {
          margin-right: 3px !important;
        }

        .w-dropdown-list {
          position: fixed;
          bottom: 0;
          right: 0;
          top: auto;
          left: auto;
          width: 100%;
          margin: 0 auto;
          height: 70%;
          max-height: 70%;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          .mobile-list {
            height: 100%;
            overflow-y: scroll;
          }
        }

        .mobile-panel {
          display: block;
          z-index: 3;
          text-align: center;
          width: 100%;
          position: fixed;
          background: white;
          padding: 10px;
          bottom: calc(70% - 4px);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
          border-radius: 5px 5px 0px 0px;
        }

        .tap-mobile-panel {
          width: 102px;
          height: 8px;
          margin: 0 auto 7px;
          background: #D9D9D9;
          border-radius: 10px;
        }
      }
    }
</style>
