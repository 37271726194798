<template>
    <div class="form-wrapper w-form">
        <validation-observer
            ref="observer"
            class="w-full in-block"
        >
            <form
                v-show="!sendSuccess"
                id="form-without-vacancy"
                name="form-without-vacancy"
                data-name="Form without vacancy"
                method="post"
                class="form"
            >
                <div class="form-fields">
                    <CustomInput
                        v-model="vacancyForm.username"
                        input-id="name-2"
                        label="Full name"
                        placeholder="Full name"
                        :type="InputTypes.name"
                        :texts="texts"
                        class="field-container"
                        :is-required="true"
                    />
                    <CustomInput
                        v-model="vacancyForm.email"
                        input-id="email"
                        label="Email"
                        placeholder="hello@example.com"
                        :type="InputTypes.email"
                        :texts="texts"
                        class="field-container"
                        :is-required="true"
                    />
                    <CustomInput
                        ref="phoneField"
                        v-model="vacancyForm.phone"
                        input-id="phone"
                        label="Phone number"
                        :type="InputTypes.phone"
                        :texts="texts"
                        class="field-container"
                        :is-required="true"
                    />
                    <div class="field-container z-index-999">
                        <label
                            id="w-node-bac95f52-7d85-e9e1-9d85-c1f4d47e1862-d47e184b"
                            for="field-2"
                            class="form-label required"
                        >
                            {{ translate('Direction', translates) }}
                        </label>
                        <validation-provider
                            v-slot="{validate, errors}"
                            name="directionDropdown"
                            :rules="{required: true}"
                            tag="div"
                            style="width: 100%"
                        >
                            <CustomDropdown
                                v-model="vacancyForm.direction"
                                :texts="texts"
                                :options="directionOptions"
                                dropdown-id="directionDropdown"
                                @input="validate"
                            />
                            <span
                                v-if="errors[0]"
                                class="validation-error-text"
                            >
                                {{ translate('This is a required field', translates) }}
                            </span>
                        </validation-provider>
                    </div>
                    <div class="field-container">
                        <label
                            id="w-node-bac95f52-7d85-e9e1-9d85-c1f4d47e1862-d47e184b"
                            for="field-2"
                            class="form-label required"
                        >
                            {{ translate('Level', translates) }}
                        </label>
                        <validation-provider
                            v-slot="{validate, errors}"
                            name="levelDropdown"
                            :rules="{required: true}"
                        >
                            <CustomDropdown
                                v-model="vacancyForm.level"
                                :texts="texts"
                                :options="levelOptions"
                                dropdown-id="levelDropdown"
                                @input="validate"
                            />
                            <span
                                v-if="errors[0]"
                                class="validation-error-text"
                            >
                                {{ translate('This is a required field', translates) }}
                            </span>
                        </validation-provider>
                    </div>

                    <UploadCV
                        class="field-container"
                        :texts="texts"
                        :csrf="csrf"
                        @update-file-name="updateFileName"
                    />
                </div>
                <validation-provider
                    v-slot="{ errors }"
                    name="checkbox"
                    :rules="{ isEqual: true }"
                    class="position-relative-vue input-wrapper"
                >
                    <label class="w-checkbox checkbox-agree custom-checkbox">
                        <div class="w-checkbox-input w-checkbox-input--inputType-custom form-checkbox" />
                        <input
                            id="confirmation-2"
                            v-model="agreement"
                            type="checkbox"
                            name="confirmation-2"
                            data-name="Confirmation 2"
                            style="opacity:0;position:absolute;z-index:-1"
                        ><span
                            class="form-checkbox-label required w-form-label"    
                            for="confirmation-2"
                        >{{ translate('I agree to the processing of personal data', translates) }}</span>
                    </label>
                    <span
                        v-if="errors[0]"
                        class="validation-error-text"
                    >
                        {{ translate('This is a required field', translates) }}
                    </span>
                </validation-provider>
                <div
                    v-if="errorMessage"
                    class="form-error-message"
                >
                    {{ errorMessage }}
                </div>
                <button
                    type="submit"
                    data-wait="Please wait..."
                    class="form-submit-button w-button"
                    @click.prevent="sendForm"
                >
                    {{ translate('Submit a request', translates) }}
                </button>
            </form>
        </validation-observer>
    </div>
</template>

<script>
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
import HiringRepository from '../../../repositories/hiring';
import PageHelper from '../../../helpers/page-helper';
import CustomInput from '../components/CustomInput.vue';
import { InputTypes } from '../constants/inputTypes';
import UploadCV from '../components/UploadCV.vue';
import { translate } from '../helpers/translate';
import CustomDropdown from '../components/CustomDropdown.vue';

extend('isEqual', {
    getMessage: field => 'Field ' + field + ' is required.',
    validate: value => value
});

export default {
    name: 'FormWithoutVacancy',
    components: {
        ValidationObserver,
        ValidationProvider,
        CustomInput,
        CustomDropdown,
        UploadCV
    },
    props: {
        csrf: {
            type: String,
            required: true
        },
        levelsProp: {
            type: String,
            required: true
        },
        directionsProp: {
            type: String,
            required: true
        },
        texts: {
            type: String,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    data: function() {
        return {
            InputTypes: {},
            agreement: false,
            translates: [],
            sendSuccess: false,
            vacancyForm: {
                username: '',
                email: '',
                phone: '',
                level: '',
                direction: '',
                fileName: ''
            },
            errorMessage: ''
        };
    },
    computed: {
        levelOptions() {
            return JSON.parse(this.levelsProp);
        },
        directionOptions() {
            return JSON.parse(this.directionsProp);
        }
    },
    mounted() {
        this.translates = JSON.parse(this.texts);
    },
    created(){
        this.InputTypes = InputTypes;
    },
    methods: {
        translate,
        updateFileName(fileName){
            this.vacancyForm.fileName = fileName;
        },
        resetVacancyForm(){
            this.vacancyForm.username = '';
            this.vacancyForm.email = '';
            this.vacancyForm.phone = '';
            const phoneFieldRef = this.$refs.phoneField;
            phoneFieldRef.clearPhone();
            this.vacancyForm.level = '';
            this.vacancyForm.direction = '';
            this.vacancyForm.fileName= '';
            this.$refs.observer.reset();
        },
        sendForm() {
            this.$refs.observer.validate().then((isValid) => {
                if (!isValid) {
                    let errorMessageString = '';
                    for(let errorItem in this.$refs.observer.errors) {
                        let errorMessages = this.$refs.observer.errors[errorItem];
                        if (!errorMessages.length) {
                            continue;
                        }
                        errorMessageString += errorItem + '-' + errorMessages.join(',') + ';';
                    }

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'no_vacancyForm_Sent_Error',
                        'deviceID': PageHelper.getDeviceID(),
                        'language': this.language,
                        'errorText': errorMessageString,
                    });
                    return;
                }

                HiringRepository.sendFormWithoutVacancy({
                    username: this.vacancyForm.username.trim(),
                    email: this.vacancyForm.email,
                    phone: this.vacancyForm.phone,
                    filename: this.vacancyForm.fileName,
                    direction: this.vacancyForm.direction,
                    level: this.vacancyForm.level
                }, this.csrf,  this.language).then(() => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'no_vacancyFormSentSuccess',
                        'deviceID': PageHelper.getDeviceID(),
                        'language': this.language
                    });
                    const formPopup = document.querySelector('.form-popup');
                    const customSuccessMessage = document.querySelector('#custom-success-message');
                    formPopup.style.display = 'none';
                    customSuccessMessage.style.display = 'block';
                    this.resetVacancyForm();
                }).catch((error) => {
                    this.errorMessage = error.response.data.message;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'no_vacancyForm_Sent_Error',
                        'deviceID': PageHelper.getDeviceID(),
                        'language': this.language,
                        'errorText': error.response.data.message,
                    });
                });
            });
        },
    }
};
</script>

<style scoped lang="scss">
.form {
    &-label {
        padding-top: 12px; 
    }

    &-error-message {
        margin-top: 16px;
        color: rgba(234, 89, 107, 1);
    }
}
.form-label {
    padding-top: 12px;
}
.field-container {
  display: grid;
  grid-template-columns: 200px 1fr;
  margin-bottom: 26px;
  position: relative;
  align-items: center;
}

.z-index-999 {
    z-index: 999;
}

@media screen and (max-width: 568px) {
    .field-container {
        grid-template-columns: 165px 1fr;
    }
}

@media screen and (max-width: 767px) {
  .field-container {
    margin-bottom: 16px;

  }

  .select-block {
    max-width: 372px;
  }

  .form {
    &-submit-button {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 479px) {
    .w-checkbox {
        padding-bottom: 0;
    }
    .field-container {
        margin: 0;
        grid-template-columns: 1fr;
        margin-bottom: 0;
    }

    .validation-error-text {
        margin: -25px 0 16px 0;
    }

    .form-label {
        margin-bottom: 8px;
        padding-top: 0;
    }
    .form-field {
        margin-bottom: 0;
    }
    .form-submit-button {
        margin-top: 0;
    }
}

.validation-error-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
  font-size: 16px;
  line-height: 24px;
  vertical-align: top;
  background: none;
  border-radius: 4px;
  color: rgba(234, 89, 107, 1);
  font-weight: 500;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.13);
}

.required::after {
    content: ' *';
    color: #7A9FFF;
  }

.form {
  &-section {
    display: flex;
  }

  &-checkbox {
    margin: 0;
  }

  &-checkbox-label {
    width: fit-content;
    margin-left: 8px;
  }

  &-submit-button {
    margin-top: 16px;
    font-weight: 600;
    line-height: 18px;;
  }

  &-select-option {
    display: block;
    padding: 12px 12px 12px 16px;
    color: rgba(236, 241, 255, 1);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}

.input-wrapper {
  position: relative;
}
.error-button {
  position: absolute;
  right: 7px;
  top: 12px;
}

.checkbox-agree {
  padding: 0;
  grid-column-gap: 0;
}

.w-full{
  width: 100%;
}

.in-block{
  display: inline-block;
}

.width-full{
  width: 100%;
  max-width: 372px;
}
</style>