class PageHelper {
    getDeviceID() {
        let deviceId = this.getCookie('_ga') || '';
        let gaBlock = new RegExp('^GA[0-9].[0-9].', 'g');

        return deviceId.replace(gaBlock, '');
    }
    getCookie(key) {
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            const [cookieKey, cookieValue] = cookie.trim().split('=');
            if (cookieKey === key) {
                return decodeURIComponent(cookieValue);
            }
        }
        return null;
    }
    pushToDataLayer(object) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(object);
    }
}

export default new PageHelper();
