<template>
    <div>
        <label
            v-if="label"
            :for="inputId"
            class="form-label"
            :class="[labelClasses, {required: isRequired}]"
        >
            {{ translate(label, translates) }}
        </label>
        <validation-provider
            v-slot="{ errors }"
            :name="type"
            :rules="{ ...rules }"
            class="input-wrapper width-full"
        >
            <input
                v-if="type !== InputTypes.textarea && type !== InputTypes.phone"
                :id="inputId"
                :value="value"
                type="text"
                class="form-field w-input"
                :placeholder="translate(placeholder, translates)"
                autocomplete="off"
                @input="handleTextInput($event)"
            >
            <input
                v-else-if="type === InputTypes.phone"
                :id="inputId"
                v-model="phone"
                class="form-field w-input"
                placeholder="+__(___)___-__-__"
                type="tel"
                maxlength="22"
                minlength="7"
                autocomplete="do-not-autofill"
                @input="changePhone"
                @click="clickPhone"
                @paste="pastePhone"
                @keyup.delete="deletePhoneKey"
            >
            <textarea
                v-else 
                :id="inputId"
                :value="value"
                class="form-field form-field-textarea"
                :placeholder="translate(placeholder, translates)"
                autocomplete="off"
                maxlength="1000"
                @input="$emit('input', $event.target.value)"
            />
            <span
                v-if="errors[0]"
                class="validation-error-text"
            >
                {{ translate(errors[0], translates) }}
            </span>
        </validation-provider>
    </div>
</template>

<script>
import { numeric, required, max, regex, email } from 'vee-validate/dist/rules';
import { extend, ValidationProvider } from 'vee-validate';
import { InputTypes } from '../constants/inputTypes';
import Inputmask from 'inputmask';
import PhoneCodes from '../../../../phone-codes.json';
import { translate } from '../helpers/translate';

extend('required', {
    ...required,
    message: 'This is a required field',
});

extend('maxName', {
    ...max,
    message: 'It\'s a great name! But it seems to have too many characters (max. 50 characters).',
});

extend('regexName', {
    ...regex,
    message: 'I think there is something extra here. Allowed characters letters, hyphen, apostrophe, space.',
});

extend('maxEmail', {
    ...max,
    message: 'Oops, your email is too long (max 50 characters).',
});

extend('email', {
    ...email,
    message: 'Oops, typo! Allowed characters latin letters, numbers, @, dot, hyphen, underscore.',
});

extend('maskIncorrectPhone', {
    params: [],
    validate() {
        let phone_selector = document.getElementById('phone');
        return phone_selector.inputmask ? phone_selector.inputmask.isComplete() : false;
    },
    message: 'Where should we call? Check the correct number format.'
});

extend('numericMonthlySalary', {
    ...numeric,
    message: 'This field is for numbers only. Please check the spelling.',
});

extend('maxMonthlySalary', {
    ...max,
    message: 'We appreciate your ambition, but let\'s try a smaller number (max. 50 characters).',
});

export default {
    components: {
        ValidationProvider
    },
    props: {
        value: {
            type: String,
            required: true
        },
        inputId: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: ''
        },
        labelClasses: {
            type: String,
            default: ''
        },
        texts: {
            type: String,
            required: true
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        }

    },
    data: () => ({
        nameRegex: '^[A-Za-zА-Яа-яІЄҐDŽіїєґéèêčřdžäöüāáǎà]([ A-Za-zА-Яа-яІЇЄҐŽDіїєґéèêčřdžäöüāáǎà`\'-]*)[A-Za-zА-Яа-яІЄҐDāŽa-zа-яїєґéèêčřdžäöüāáǎà ]$',
        inputValue: '',
        InputTypes: {},
        email: '',
        phone: null,
        phoneMask: '',
        oldPhoneValue: '',
        defaultPhoneMask: '+99(999)999-99-99',
        phoneSelector: '',
        translates: []
    }),
    computed: {
        rules() {
            if (this.type === InputTypes.name) {
                return { required: this.isRequired, maxName: 50, regexName: this.nameRegex };
            }

            if (this.type === InputTypes.email) {
                return { required: this.isRequired, maxEmail: 50, email };
            }

            if (this.type === InputTypes.phone) {
                return { required: this.isRequired, maskIncorrectPhone: true };
            }

            if (this.type === InputTypes.salary) {
                return { required: this.isRequired, numericMonthlySalary: true, maxMonthlySalary: 10 };
            }
            return {required: this.isRequired};
        }
    },
    created() {
        this.InputTypes = InputTypes;
    },
    mounted(){
        this.phoneSelector = document.getElementById('phone');
        this.translates = JSON.parse(this.texts);
    },
    methods: {
        changePhone: function () {
            let cleanPhone = this.phone.replace(/\D/g, '');
            let defaultPhoneMask = this.defaultPhoneMask;
            if (this.phone.length > 0) {
                this.oldPhoneValue = cleanPhone;
            }
            let oldPhone = this.oldPhoneValue;
            let phoneSelector = this.phoneSelector;
            let showMask = '';
            let phoneWithoutMask;
            let matchResults;

            for (let i = 6; i > 0; i--) {
                matchResults = this.searchMatch(cleanPhone.slice(0, i), cleanPhone);
                if (matchResults.countMatch > 0 && matchResults.completeMatch) {
                    showMask = matchResults.completeMatch;
                    phoneWithoutMask = cleanPhone.replace(matchResults.clearMask, '');
                    break;
                }
            }

            if (showMask.length > 0) {
                this.phone = phoneWithoutMask;
                this.phoneMask = showMask;
                phoneSelector.value = phoneWithoutMask;

                Inputmask(showMask, {
                    definitions: {
                        '#': {
                            validator: '[0-9]',
                            cardinality: 1,
                            definitionSymbol: '*'
                        },
                    },
                    'oncleared': function () {
                        phoneSelector.inputmask.remove();
                        phoneSelector.value = '+' + oldPhone.slice(0, -1);
                        Inputmask({mask: defaultPhoneMask}).mask(phoneSelector);
                    }
                }
                ).mask(phoneSelector);
            }

            if (phoneSelector.inputmask && phoneSelector.inputmask.isComplete()) {
                phoneSelector.inputmask.remove();
                phoneSelector.value = this.phone;
                Inputmask({mask: showMask}).mask(phoneSelector);
                this.phone = phoneSelector.value;
            }
            this.$emit('input', this.phone);
        },
        searchMatch: function (phone, cleanPhone) {
            let countMatch = 0;
            let completeMatch = null;
            let clearMask = null;

            PhoneCodes.forEach(function (item) {
                let mask = item.mask.replace(/\D/g, '');
                let maskLength = item.mask.replace(/#/gi, '9').replace(/\D/g, '').length;
                let phoneRegular = new RegExp('^' + phone);
                let match = phoneRegular.exec(mask);

                if (!match) {
                    return;
                }

                if (mask !== phone || maskLength <= cleanPhone.length){
                    return;
                }

                countMatch++;
                completeMatch = item.mask;
                completeMatch = item.mask.replace(/9/gi, '\\9');

                if (countMatch > 1 && maskLength === cleanPhone.length) {
                    completeMatch = completeMatch.concat('[#]');
                }

                clearMask = mask;
            });
            return {'countMatch': countMatch, 'completeMatch': completeMatch, 'clearMask': clearMask};
        },
        clickPhone: function () {
            let phone_selector = document.getElementById('phone');
            if (!this.phoneSelector.inputmask) {
                Inputmask({mask: this.defaultPhoneMask}).mask(phone_selector);
            }
        },
        pastePhone: function () {
            if (this.phoneSelector.inputmask) {
                this.phoneSelector.inputmask.remove();
            }
        },
        deletePhoneKey: function (e) {
            if (e.target.value !== '') {
                return;
            }

            if (!this.phoneSelector.inputmask) {
                return;
            }

            this.phoneSelector.inputmask.remove();
            this.oldPhoneValue = this.oldPhoneValue.slice(0, -2);
            this.phoneSelector.value = '+' + this.oldPhoneValue;
            Inputmask({mask: this.defaultPhoneMask}).mask(this.phoneSelector);
        },
        clearPhone() {
            Inputmask({mask: this.defaultPhoneMask}).mask(this.phoneSelector);
            this.phone = '';
        },
        handleTextInput(event) {
            this.$emit('input', event.target.value);
        },
        translate
    }
};
</script>

<style lang="scss" scoped>
.width-full{
    width: 100%;
}

.align-self-start {
    align-self: start;
}

.validation-error-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
  font-size: 16px;
  line-height: 24px;
  vertical-align: top;
  background: none;
  border-radius: 4px;
  color: rgba(234, 89, 107, 1);
  font-weight: 500;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.13);
}

.form-field-textarea {
    resize: vertical;
}

.error-button {
  position: absolute;
  right: 7px;
  top: 12px;
}

.input-wrapper {
  display: flex;
  justify-content: end;
  position: relative;
  flex-direction: column;
}

.form-label.required::after {
    content: ' *';
    color: #7A9FFF;
}

.form-label {
    margin-bottom: 0;
    padding-top: 0;
}

@media screen and (max-width: 479px) {
  .form-field {
    margin-bottom: 25px;
  }

  .form-label {
    margin-bottom: 4px;
  }

  .validation-error-text {
    margin: -25px 0 16px 0;
  }
}

@media screen and (max-width: 767px) {
  .error-button {
    top: 9px;
  }
}
</style>